import React, { Component } from "react";

import { Table, Button } from "antd";
import ReactAudioPlayer from "react-audio-player"
import axios from "axios";

class StudentRatings extends Component {
  state = { 
            fetching: true, 
            dataSource: [], 
          };

  componentDidMount = async () => {
    let urlSplit = window.location.href.split("/")
    let courseId = urlSplit[4]
    let userId = urlSplit[6]
    const {data: dataSource} = await axios.get(`/ratings/get_user_ratings/?userId=${userId}&courseId=${courseId}`)
    console.log(dataSource)
    this.setState({ fetching: false, dataSource: dataSource})
  };

  TableView = () => {
    const { dataSource, fetching } = this.state;
    const columns = [
      {
        title: "Submitted By",
        dataIndex: "submitted_by",
        editable: false,
        key: "submitted_by",
        render: (text, record) => {
          return (
            <Button type="link">{record.submission.user_id}</Button>
          )
        }
      },
      {
        title: "Answer",
        key: "answer",
        dataIndex: "answer",
        render: (text, record) => {
          let url = process.env.REACT_APP_ASSET_URL+"/"+record.submission.answer
          return (
            <div>
              <ReactAudioPlayer src={url} controls />
            </div>
          )
        }
      },
      {
        title: "Rating",
        key: "mark",
        dataIndex: "mark",
        render: (text, record) => {
          let object = JSON.parse(record.rating)
          return (
            <div>
              <p>Clarity: {object.clarity}</p>
              <p>Inotation: {object.inotation}</p>
              <p>Pronunciation: {object.pronunciation}</p>
            </div>
          )
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", paddingBottom: 20, fontSize: 30}}>
            Ratings made by student
          </span>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={dataSource}
            onRow={record => {
              return {
                onDoubleClick: event => { console.log(record) }
              }
            }}
          />

        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        {this.TableView()}
      </div>
    )
  }
}

export default StudentRatings