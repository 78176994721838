import React, { Component } from "react";

import { Redirect } from "react-router-dom"
import axios from "axios";
import queryString from "query-string"


class Lti extends Component {

  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      module: undefined,
      isAdmin: false,
      ltiModule: undefined,
    }
  }

  componentDidMount = async () => {
    let values = queryString.parse(window.location.href)
    const {data: dataSource} = await axios.post("auth/lti_validation/", { token: values.token }, {
      headers: { 
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": null,
      }
    })
    axios.defaults.headers.common["Authorization"] = "Bearer " + dataSource.access
    sessionStorage.setItem("userId", dataSource.user_id)
    sessionStorage.setItem("isAdmin", dataSource.is_admin)
    sessionStorage.setItem("first_name", dataSource.first_name)
    sessionStorage.setItem("access", dataSource.access)
    sessionStorage.setItem("storage", dataSource.storage)
    const {data: ltiDetails} = await axios.get("modules/get_lti_module/?id="+dataSource.context_id) 
    // now need to check the lti module and if it's linked to a module yet.
    // if not linked, take to general module page to link FOR NOW
    // if it is linked, then take to specific module page
    // first need to create specific module page.
    // this.setState({ redirect: true })
    this.setState({ ltiModule: ltiDetails, isAdmin: dataSource.is_admin, module: ltiDetails.module, redirect: true})
  };

  render() {
    if (!this.state.redirect)
      return (
        <div>
          LTI Page
        </div>
      )
    else {
      
      if (this.state.module !== null) {
        let link
        if (this.state.isAdmin || !this.state.module.map_to_exercise) {
          link = `/courses/${this.state.ltiModule.course}/module/${this.state.module.id}`
          return (<Redirect to={link} />)
        } else {
          // student and needs to be redirected.
          let exercise = this.state.module.exercises[0].id
          link = `/courses/${this.state.ltiModule.course}/module/${this.state.module.id}/exercise/${exercise}`
          return (<Redirect to={link} />)
        }
      } else { // it is null.
        if (this.state.isAdmin) { // needs to be allocated. take this to a linking page.
          let link = `/link_module/&lti=${this.state.ltiModule.resource_link_id}&courseId=${this.state.ltiModule.course}`
          return (<Redirect to={link} />)
        } else { // student and unallocated.
          return (<Redirect to="/forbidden" />)
        }
      }
    }
  }
}

export default Lti