import React, { Component } from "react";

import { Table, Input, Icon, Tag, Button, Divider, notification, Tabs, Breadcrumb  } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import QuestionView from "./QuestionView";
import RatingTable from './RatingTable.js'
import RateProcess from './RateProcess'
import MarkTable from './MarkTable.js'
const { TabPane } = Tabs

class Exercise extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      searchText: "",
      exercise: undefined,
      visibleQuestionModal: false,
      selectedQuestion: undefined,
      visibleRatingModal: false,
      courseId: undefined,
      moduleId: undefined,
      exerciseId: undefined
    }
  }

  componentDidMount = async () => {
    let urlSplit = window.location.href.split("/")
    let exerciseId = urlSplit[8]
    let moduleId = urlSplit[6]
    let courseId = urlSplit[4]
    const userId = sessionStorage.getItem('userId')
    const { data: exerciseData } = await axios.get("exercises/exercises/?id=" + exerciseId);
    const { data: dataSource } = await axios.get(`submissions/get_user_submissions/?userId=${userId}&moduleId=${moduleId}&exerciseId=${exerciseId}`)
    const { submissions: submissionData, ratings: ratingsData } = dataSource
    // now we need to match submissions to questions
    // match submissions to their 
    // IMPLEMENT BINARY SEARCH FOR THIS. FOR NOW USE INEFFICIENT METHOD.
    let submissionRatings = {}
    ratingsData.forEach(rating => {
      if (submissionRatings[rating.submission_id] == undefined) {
        submissionRatings[rating.submission_id] = []
      }
      submissionRatings[rating.submission_id].push(rating)
    })
    exerciseData.questions.forEach(question => {
      if (submissionData !== "") {
        const index = submissionData.findIndex(
          record => record.question_id === question.id
        );
        if (index !== -1) {
          if (submissionRatings[submissionData[index].id] != undefined) {
            submissionData[index].ratings = submissionRatings[submissionData[index].id]
          } else submissionData[index].ratings = []
          question.submission = submissionData[index]
        } else {
          question.submission = undefined
        }
      }
    })
    this.setState({ exercise: exerciseData, fetching: false, courseId, moduleId, exerciseId })
  };

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource;
    return dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
    );
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  onCancel = () => {
    this.setState({
      visibleQuestionModal: false,
      selectedQuestion: undefined
    })
  }

  questionSaved = (submission) => {
    this.onCancel()
    this.openNotificationWithIcon("success", "Answer for question has been saved.");
    // now update the table to show that the status has been completed.
    const index = this.state.exercise.questions.findIndex(
      record => record.id === submission.question_id
    );
    let question = this.state.exercise.questions[index]
    question.submission = submission

    const newQuestions =
      [
        ...this.state.exercise.questions.slice(0, index),
        question,
        ...this.state.exercise.questions.slice(index + 1)
      ];
    
    let currentExercise = this.state.exercise
    currentExercise.questions = newQuestions
    this.setState({ exercise: currentExercise })
  //   this.setState({
  //     dataSource,
  //     visibleModuleForm: false,
  //     fetching: false
  //   });
  }

  TableView = () => {
    const { fetching, searchText, exercise } = this.state;
    let dataSource = exercise.questions
    const columns = [
      {
        title: "Question Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (text, record) => {
          return (
            <div>
              {text}
            </div>
          )
        }
      },
      {
        title: "Status",
        key: "submission",
        dataIndex: "submission",
        render: (text, record) => {
          if (record.submission !== undefined)
          return (
            <div>
              <Tag color="green">Completed</Tag>
            </div>
          )
          else return (
            <div>
              <Tag color="red">Not Completed</Tag>
            </div>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          return (
            <div>
              <Button type="link" disabled={record.submission !== undefined}
                onClick={() => {
                  this.setState({
                    visibleQuestionModal: true,
                    selectedQuestion: record
                  })
                }}
              >
                View
              </Button>
            </div>
          )
        }
      }
    ]
    return (
      <>
        <div style={{ width: "100%" }}>
          
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={false}
          />
        </div>
      </>
    )
  }

  render() {
    let urlSplit = window.location.href.split("/")
    if (!this.state.fetching) {
      let ratings = true
      if (this.state.exercise.type === "pronunciation") {
        ratings = false
      }
      return (
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Questions" key="1">
            <div style={{paddingTop: 40}}>
              <h1>
                {this.TableView()}
              </h1>
            </div>
            </TabPane>
            <TabPane tab="Ratings" key="2" disabled={ratings}>
              <div>
                <RatingTable selectedExercise={this.state.exercise} />
                <Button
                  disabled={this.state.exercise.results_released}
                  onClick={() => {
                    this.setState({ visibleRatingModal: true })
                    // window.open(`/rate/&courseId=${urlSplit[4]}&moduleId=${urlSplit[6]}&exerciseId=${urlSplit[8]}`)
                  }}
                >Start a rating</Button>
              </div> 
            </TabPane>
            <TabPane tab="Results" key="3" disabled={!this.state.exercise.results_released}>
              <div>
                <MarkTable exerciseData={this.state.exercise} />
              </div> 
            </TabPane>
          </Tabs>
          <RateProcess 
            visible={this.state.visibleRatingModal}
            onCancel={() => {this.setState({visibleRatingModal: false})}}
            courseId={this.state.courseId}
            moduleId={this.state.moduleId}
            exerciseId={this.state.exerciseId}
          />
          <QuestionView
            visible={this.state.visibleQuestionModal}
            selectedQuestion={this.state.selectedQuestion}
            onCancel={this.onCancel}
            type={this.state.exercise.type}
            questionSaved={this.questionSaved}
          />
        </div>
      )
    }

    else
    return (
      <div>
      </div>
    )
  }
}

export default Exercise