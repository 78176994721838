import React, { Component } from "react";
import { Modal, Button, Tag, Table, Input } from "antd";
import ReactAudioPlayer from "react-audio-player";

class QuestionDuplicate extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      title: "",
      description: ""
    }
  }

  handleTitleChange = (e) => {
    this.setState({title: e.target.value})
  }

  handleDescriptionChange = (e) => {
    this.setState({description: e.target.value})
  }

  render() {
    let questionDetails
    if (this.props.selectedQuestion !== undefined) {
      let questionData
      // if (this.props.selectedQuestion.type === "pronunciation") {
      //   // two audio files.
      //   let audio1 = process.env.REACT_APP_ASSET_URL+"/"+this.props.selectedQuestion.assets[0].s3_link
      //   let audio2 = process.env.REACT_APP_ASSET_URL+"/"+this.props.selectedQuestion.assets[1].s3_link
      //   questionData = (
      //     <div style={{padding: 10}}>
      //       <p>Question Assets: </p>
      //       <ReactAudioPlayer src={audio1} controls />
      //       <ReactAudioPlayer src={audio2} controls />
      //     </div>
      //   )
      // } else if (this.props.selectedQuestion.type === "mcq") {}
      questionData = (
        <div style={{padding: 10}}>
          {this.props.selectedQuestion.assets.map((asset, index) => {
            let assetSrc = process.env.REACT_APP_ASSET_URL+"/"+asset.s3_link
            if (asset.type === "audio") {
              return (
                <div key={asset.id}>
                  <ReactAudioPlayer src={assetSrc} controls />
                </div>
              )
            } else if (asset.type === "picture") {
              return (
                <div key={asset.id}>
                  <img src={assetSrc} width="300" />
                </div>
              )
            }
            return null
          })}
        </div>
      )
      questionDetails = (
        <div>
          <p style={{fontWeight: 'bold', fontSize: 24}}>To be duplicated:</p>
          <p>Title: {this.props.selectedQuestion.title}</p>
          <p>Description: {this.props.selectedQuestion.description}</p>
          <p>Question Type: {this.props.selectedQuestion.type}</p>
          <p>Question Assets: </p>
          {questionData}
        </div>
      )
    }
    return (
      <Modal
        visible={this.props.visible}
        onOk={() => this.props.onCancel()}
        onCancel={() => this.props.onCancel()}
        style={{minWidth: "50%"}}
        footer={[
          <Button onClick={() => {this.props.onCancel()}} key={1}>
            OK
          </Button>
        ]}
      >
        <div style={{margin: 20}}>
          <p style={{fontSize: 24, fontWeight: "bold"}}>New Duplicate Details</p>
          <p style={{fontWeight: "bold"}}>Title</p>
          <Input onChange={this.handleTitleChange} value={this.state.title} style={{marginLeft: 20}} placeholder="New Duplicate Title"/>
          <p style={{fontWeight: "bold"}}>Description</p>
          <Input onChange={this.handleDescriptionChange} value={this.state.description} style={{marginLeft: 20}} placeholder="New Duplicate Description"/>
          <Button type="primary" style={{marginTop: 20, float: "right"}}
            onClick={() => {this.props.duplicateQuestion(this.props.selectedQuestion, this.state.title, this.state.description)}}
          >
            Duplicate</Button>
        </div>
        <div style={{margin: 20}}>
          {questionDetails}
        </div>
      </Modal>
    )
  }
}

export default QuestionDuplicate