import React, { Component } from "react";
import { Modal, Button, Tag, Table, Input } from "antd";

class DuplicateExercise extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      exercise: undefined,
      title: "",
      description: ""
    }
  }

  componentDidMount = async () => {
  };

  handleTitleChange = (e) => {
    this.setState({title: e.target.value})
  }

  handleDescriptionChange = (e) => {
    this.setState({description: e.target.value})
  }

  questionTable = (questions) => {
    const columns = [
      {
        title: "Question Title",
        key: "title",
        dataIndex: "title",
        render: (text) => {
          return (<p>{text}</p>)
        }
      },
      {
        title: "Question Description",
        key: "description",
        dataIndex: "description",
        render: (text) => {
          return <p>{text}</p>
        }
      },
      { 
        title: "Question Text",
        key: "question_text",
        dataIndex: "question_text",
        render: (text) => {
          if (text === " ") {
            return <p>Please pronounce the following word</p>
          } else {
            return <p>{text}</p>
          }
        }
      },
      {
        title: "Question Type",
        key: "type",
        dataIndex: "type",
        render: (text) => {
          if (text === "pronunciation") {
            return <Tag color="blue">Pronunciation</Tag>
          } else {
            return <Tag color="red">MCQ</Tag>
          }
        }
      }
    ]
    return (
      <Table
        rowKey={record => record.id}
        bordered
        columns={columns}
        dataSource={questions}
      />
    )
  }

  render() {
    let exerciseDetails
    if (this.props.selectedExercise !== undefined) {
      console.log(this.props.selectedExercise)
      exerciseDetails = (
        <div>
          <p style={{fontWeight: 'bold', fontSize: 24}}>To be duplicated:</p>
          <p>Title: {this.props.selectedExercise.title}</p>
          <p>Description: {this.props.selectedExercise.description}</p>
          {this.questionTable(this.props.selectedExercise.questions)}
        </div>
      )
    }
    return (
      <Modal
        visible={this.props.visible}
        onOk={() => this.props.onCancel()}
        onCancel={() => this.props.onCancel()}
        style={{minWidth: "50%"}}
        footer={[
          <Button onClick={() => {this.props.onCancel()}} key={1}>
            OK
          </Button>
        ]}
      >
        <div style={{margin: 20}}>
          <p style={{fontSize: 24, fontWeight: "bold"}}>New Duplicate Details</p>
          <p style={{fontWeight: "bold"}}>Title</p>
          <Input onChange={this.handleTitleChange} value={this.state.title} style={{marginLeft: 20}} placeholder="New Duplicate Title"/>
          <p style={{fontWeight: "bold"}}>Description</p>
          <Input onChange={this.handleDescriptionChange} value={this.state.description} style={{marginLeft: 20}} placeholder="New Duplicate Description"/>
          <Button type="primary" style={{marginTop: 20, float: "right"}}
            onClick={() => {this.props.duplicateExercise(this.props.selectedExercise, this.state.title, this.state.description)}}
          >
            Duplicate</Button>
        </div>
        <div style={{margin: 20}}>
          {exerciseDetails}
        </div>
      </Modal>
    )
  }
}

export default DuplicateExercise