import React from  "react"
import { Button, Spin, Popconfirm } from 'antd'
import { AudioOutlined, StopFilled } from "@ant-design/icons"
import ReactAudioPlayer from "react-audio-player"
import AudioAnalyser from '../Audio/AudioAnalyser'
import MicRecorder from "mic-recorder-to-mp3"
import axios from "axios"


class Pronunciation extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      recording: false,
      recordingBlob: undefined,
      base64Audio: undefined,
      record: false,
      recorder: new MicRecorder({ bitRate: 128 }),
      audio: undefined
    }
  }

  handleSubmit = async () => {
    // get the blob and send it straight to the backend.
    if (this.state.recordingBlob === undefined) {
      this.openNotificationWithIcon("error", "Please record an answer before submitting.")
      return
    }
    let blob = await fetch(this.state.recordingBlob).then(r => r.blob()).then(blobFile => new File([blobFile],
      "recording", { type: "audio/wav" }))
    const formData = new FormData()
    formData.append('files[]', blob)
    // also need to get question, exercise and module IDs + userId
    let userId = sessionStorage.userId
    let urlSplit = window.location.href.split('/')
    let courseId = urlSplit[4]
    let moduleId = urlSplit[6]
    let exerciseId = urlSplit[8]
    let type = this.props.selectedQuestion.type
    formData.append('meta', JSON.stringify({
      courseId: courseId,
      userId: userId,
      moduleId: moduleId,
      exerciseId: exerciseId,
      questionId: this.props.selectedQuestion.id,
      type: type
    }))
    const response = await axios.post("submissions/submissions/", formData)
    if (response.status === 200) {
      // this means we can tick submitted and close the box.
      this.props.questionSaved(response.data)
    }
  }

  onStop = (recordedBlob) => {
    this.setState({ recordingBlob: recordedBlob.blobURL })
  }

  async getMicrophone() {
    const audio = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    });
    this.setState({ audio });
  }

  stopMicrophone() {
    this.state.audio.getTracks().forEach(track => track.stop());
    this.setState({ audio: null });
  }

  startRecording = () => {
    this.setState({ record: true });
    this.getMicrophone()
    this.state.recorder.start()
  }

  stopRecording = () => {
    this.stopMicrophone()
    this.setState({ record: false });
    this.state.recorder.stop().getMp3().then(([buffer, blob]) => {
      const file = new File(buffer, 'recording.mp3', {
        type: blob.type,
        lastModified: Date.now()
      })
      let url = URL.createObjectURL(file)
      this.setState({recordingBlob: url})
    })
  }

  render = () => {
    const selectedQuestion = this.props.selectedQuestion
    let button
    let examples = selectedQuestion.assets.map((asset, index) => {
      let link = process.env.REACT_APP_ASSET_URL+"/"+asset.s3_link
      return (
        <ReactAudioPlayer src={link} controls />
      )
    })
    if (this.state.record) {
      button = (
        <Button style={{margin: 20}} onClick={this.stopRecording}>
          <StopFilled />Stop Recording
        </Button>
      )
    } else if (!this.state.record && this.state.recordingBlob === undefined) {
      button = (
        <Button style={{margin: 20}} onClick={this.startRecording}>
          <AudioOutlined /> Start Recording
        </Button>
      )
    } else {
      button = (
        <Button style={{margin: 20}} onClick={this.startRecording}>
          <AudioOutlined /> Redo Recording
        </Button>
      )
    }
    let questionData = (
      <div style={{flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
        <p>Examples</p>
        { examples }
        <p>Submit your own recording:</p>
        {button}
        { this.state.record ? (
          <Spin tip="Recording...">
          </Spin>
        ) : null }
        {this.state.audio ? <AudioAnalyser audio={this.state.audio} /> : ''}
        { this.state.recordingBlob && !this.state.recording ? (
          <ReactAudioPlayer src={this.state.recordingBlob} controls autoPlay />
        ) : null }

        { this.props.submit ? (
          <Popconfirm
            title="You can only submit once. Are you sure this is the recording you want to submit?"
            onConfirm={() => this.handleSubmit()}
          >
            <Button
              type="primary"
            >
              Submit
            </Button>
          </Popconfirm>) : (null)
        }
      </div>
    )
    return (
      <div>
        {questionData}
      </div>
    )
  }
}

export default Pronunciation