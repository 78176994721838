import React, { Component } from "react";
import {  Button, Collapse, Table, notification, Tag } from "antd";
import axios from "axios"
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom"
import AdminRate from "./AdminRate.js"

const { Panel } = Collapse

class CourseExercise extends Component {

  constructor(props) {
    super(props)
    this.state = {
      exerciseData: {
        questions: []
      },
      adminRateVisible: false,
      selectedQuestion: undefined,
      selectedSubmission: undefined,
      rows: [],
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  getData = async () => {
    let urlSplit = window.location.href.split("/")
    // get the exercises
    const { data: exerciseData } = await axios.get("exercises/exercises/?id="+urlSplit[8])
    // get all submissions for this course/module/exercise
    const { data: dataSource } = await axios.get(`submissions/get_user_submissions/?moduleId=${urlSplit[6]}&exerciseId=${urlSplit[8]}`)
    // for each submission, push the submission into a question dict
    let { submissions: submissionData, ratings: ratingData } = dataSource
    // do the same for ratings
    let submissionRatings = {}
    ratingData.forEach(r => {
      if (submissionRatings[r.submission_id] == undefined) {
        submissionRatings[r.submission_id] = []
      }
      submissionRatings[r.submission_id].push(r)
    })
    let questions = {}
    submissionData.forEach(sub => {
      // first add the ratings.
      if (submissionRatings[sub.id] != undefined) {
        sub.ratings = submissionRatings[sub.id]
      } else {
        sub.ratings = []
      }
      if (questions[sub.question_id] == undefined) {
        questions[sub.question_id] = []
      }
      questions[sub.question_id].push(sub)
    })
    let rows = []
    // now merge this into the exercise data object.
    exerciseData.questions.forEach(q => {
      if (questions[q.id] != undefined) {
        questions[q.id].forEach(submission => {
          let object = {
            question: q,
            submission: submission
          }
          rows.push(object)
        })
      } else {
        q.submissions = []
      }
    })
    this.setState({ exerciseData, rows })
  }

  componentDidMount = async () => {
    // Get all the exercises linked to this course.
    this.getData()
  };

  // returns a object of ratings for pro, ino, clarity
  calculateAverageRating = (ratingList) => {
    let averageRatings = {
      clarity: 0,
      inotation: 0,
      pronunciation: 0
    }
    if (ratingList.length !== 0) {
      ratingList.forEach(r => {
        let object = JSON.parse(r.rating)
        averageRatings.clarity += parseInt(object.clarity)
        averageRatings.inotation += parseInt(object.inotation)
        averageRatings.pronunciation += parseInt(object.pronunciation)
      })
    }
    averageRatings.clarity = averageRatings.clarity/(ratingList.length)
    averageRatings.pronunciation = averageRatings.pronunciation/(ratingList.length)
    averageRatings.inotation = averageRatings.inotation/(ratingList.length)
    return averageRatings
  }

  addAdminRating = (submitData) => {
    // now add this rating to the correct question then submission
    let questionId = submitData.submission.question.id
    let submissionId = submitData.submission.id
    let rows = this.state.rows
    let submissionIndex = rows.findIndex(
      record => record.submission.id === submissionId
    )
    rows[submissionIndex].submission.ratings.push(submitData)
    this.setState({ rows, adminRateVisible: false, selectedQuestion: undefined, selectedSubmission: undefined })
    this.openNotificationWithIcon("success", "Success", `The rating has been successfully saved.`)
  }

  TableView = () => {
    const columns = [
      { 
        title: "Question Title",
        dataIndex: "question_title",
        key: "question_title",
        render: (text, record) => {
          return (
            <p>{record.question.title}</p>
          )
        }
      },
      {
        title: "Question Description",
        dataIndex: "question_description",
        key: "question_description",
        render: (text, record) => {
          return (
            <p>{record.question.description}</p>
          )
        }
      },
      {
        title: "Submitted By",
        dataIndex: "submission_user",
        editable: false,
        key: "submission_user",
        render: (text, record) => {
          return (
            <p>{record.submission.user_id}</p>
          )
        }
      },
      {
        title: "Answer",
        key: "answer",
        dataIndex: "answer",
        render: (text, record) => {
          let url = process.env.REACT_APP_ASSET_URL+"/"+record.submission.answer
          return (
            <ReactAudioPlayer src={url} controls />
          )
        }
      },
      {
        title: "Marks",
        key: "mark",
        dataIndex: "mark",
        render: (text, record) => {
          if (this.state.exerciseData.type === "mcq") {
            if (record.mcq_correct) {
              return (
                <Tag color="green">
                  Correct
                </Tag>
              )
            } else {
              return (
                <Tag color="red">
                  Incorrect
                </Tag>
              )
            }
          }
          let averageMarks = this.calculateAverageRating(record.submission.ratings)
          return (
            <div>
              <p># Times Rated: {record.submission.ratings.length}/{this.state.exerciseData.min_reviews}</p>
              <p>Clarity: {averageMarks.clarity}</p>
              <p>Inotation: {averageMarks.inotation}</p>
              <p>Pronunciation: {averageMarks.pronunciation}</p>
            </div>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          return (
            <Button type="link"
              onClick={() => {this.setState({adminRateVisible: true, selectedQuestion: record.question, selectedSubmission: record.submission})}}
            >
              Add Rating
            </Button>
          )
        }
      }
    ]

    return (
      <div>
        <Table 
          rowKey={record => record.submission.id}
          columns={columns}
          dataSource={this.state.rows}
          pagination={false}
        />
      </div>
    )
  }

  render() {

    return (
      <div>
        <div style={{textAlign: "start", justifyContent: "center", fontSize: 14}}>
          <p>Exercise: {this.state.exerciseData.title}</p>
          <p>Description: {this.state.exerciseData.description}</p>
          <p>Type: {this.state.exerciseData.type}</p>
        </div>
        {this.TableView()}
        <AdminRate 
          visible={this.state.adminRateVisible} 
          onCancel={() => {this.setState({adminRateVisible: false, selectedQuestion: undefined, selectedSubmission: undefined})}}
          selectedQuestion={this.state.selectedQuestion}
          selectedSubmission={this.state.selectedSubmission}
          addAdminRating={this.addAdminRating}
        />
      </div>
    )
  }
}

export default CourseExercise