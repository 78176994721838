import React, { Component } from "react";

import { Table, Input, Icon, Tag, Button, Divider, notification, Breadcrumb } from "antd";
import { Link } from "react-router-dom"
import Highlighter from "react-highlight-words";
import axios from "axios";

class CourseModules extends Component {
  state = { 
    fetching: true, 
    dataSource: [], 
    completed: {}
  };

  componentDidMount = async () => {
    let urlSplit = window.location.href.split("/")
    let courseId = urlSplit[4]
    const {data: dataSource} = await axios.get("courses/get_course/?id="+courseId)
    console.log(dataSource)
    this.setState({ fetching: false, course: dataSource.data, users: dataSource.data.users, completed: dataSource.extra })
  };

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource;
    return dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
    );
  }

  TableView = () => {
    const { users, fetching, searchText } = this.state;
    const columns = [
      {
        title: "zID",
        dataIndex: "zid",
        key: "zid",
        editable: false,
        render: (text, record) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Student Name",
        dataIndex: "name",
        editable: false,
        key: "name",
        render: (text, record) => {
          return (
            <div>
              <p>{record.first_name} {record.last_name}</p>
            </div>
          )
        }
      },
      {
        title: "Completed",
        dataIndex: "completed",
        key: "completed",
        render: (text, record) => {
          let completed = this.state.completed[record.zid]
          return (
            <div>
              <p>{completed.submissions}/{completed.total} - <b>{((completed.submissions/completed.total)*100).toFixed(2)}%</b></p>
            </div>
          )
        }
      },
      {
        title: "Ratings",
        dataIndex: "ratings",
        key: "ratings",
        render: (text, record) => {
          let completed = this.state.completed[record.zid]
          return (
            <div>
              <p>{completed.ratings}</p>
            </div>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {

          let urlSplit = window.location.href.split("/")
          let courseId = urlSplit[4]
          return (
            <div>
              <Link
                to={{
                  pathname: `/courses/${courseId}/student/${record.zid}`,
                }}
                onClick={() => {this.props.addBreadcrumb(`/courses/${courseId}/student/${record.zid}`, `Student - ${record.zid}`)}}
              >View</Link>
            </div>
          )
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40}}>
            Students
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(users, searchText)}
            onRow={record => {
              return {
                onDoubleClick: event => { console.log(record) }
              }
            }}
          />

        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        {this.TableView()}
      </div>
    )
  }
}

export default CourseModules