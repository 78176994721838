import React, { Component } from "react";

import { Tabs } from "antd";
import axios from "axios";
import CourseModules from "./CourseModules.js"
import CourseStudents from "./CourseStudents.js"
const { TabPane } = Tabs

class SingleCourseView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      dataSource: [],
    }
  }

  componentDidMount = async () => {
    let urlSplit = window.location.href.split('/')
    let courseId = urlSplit[4]
    const {data: dataSource} = await axios.get("courses/get_course/?id="+courseId)
    this.setState({ fetching: false, dataSource })
  };

  render() {
    return (
      <div>
        <Tabs size="large" type="card" defaultActiveKey="1">
          <TabPane key="1" tab="All Students">
            <CourseStudents addBreadcrumb={this.props.addBreadcrumb}/>
          </TabPane>
          <TabPane key="2" tab="Modules">
            <CourseModules course={this.state.dataSource} addBreadcrumb={this.props.addBreadcrumb}/>
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default SingleCourseView