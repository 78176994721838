import React, { Component } from "react";
import { notification, Collapse, Tabs, Table, Tag } from "antd";
import axios from "axios"
import StudentRatings from "./StudentRatings"
import ReactAudioPlayer from "react-audio-player";
const { Panel } = Collapse

class CourseStudentView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      moduleRows: [],
      userData: undefined,
    }
  }

  componentDidMount = async () => {
    // Get student details
    let urlSplit = window.location.href.split("/")
    let zid = urlSplit[6]
    const { data: userData } = await axios.get("/auth/get_user/?zid="+zid)
    // Get all the exercises linked to this course.
    console.log(userData)
    const { data: dataSource } = await axios.get("modules/get_lti_by_course/?course="+urlSplit[4])
    // for each ltimodule, get the module
    let extractedModules = []
    dataSource.forEach(lti => {
      if (lti.module !== null && lti.module !== undefined) extractedModules.push(lti.module)
    })
    const { data: submissionsSource } = await axios.get("submissions/get_user_submissions/?courseId="+urlSplit[4]+"&userId="+urlSplit[6])
    // need to also combine each of the questions with submissions if they exist.
    // AND THEN eventually ratings.
    // first sort the submissionData by module, ex, and questions. Then pair it with each mod/ex/ques (already done in Django)
    // submissions will usually < than questions.
    let { submissions: submissionData, ratings: ratingsData } = submissionsSource
    let submissionRatings = {}
    ratingsData.forEach(r => {
      if (submissionRatings[r.submission_id] === undefined) {
        submissionRatings[r.submission_id] = []
      }
      submissionRatings[r.submission_id].push(r)
    })
    submissionData.forEach(sub => {
      // find the index in the modules and that.
      // also add ratings to the submission
      if (submissionRatings[sub.id] !== undefined) {
        sub.ratings = submissionRatings[sub.id]
      } else {
        sub.ratings = []
      }
      const modIndex = extractedModules.findIndex(mod => {
        return sub.module_id === mod.id
      })
      const exIndex = extractedModules[modIndex].exercises.findIndex(ex => {
        return sub.exercise_id === ex.id
      })
      const qIndex = extractedModules[modIndex].exercises[exIndex].questions.findIndex(q => {
        return sub.question_id === q.id
      })
      extractedModules[modIndex].exercises[exIndex].questions[qIndex].submission = sub
    })
    let moduleRows = []
    extractedModules.forEach(mod => {
      // for each module make a table.
      mod.exercises.forEach(ex => {
        ex.questions.forEach(question => {
          let sub = undefined
          if (question.submission !== undefined) {
            sub = question.submission
          }
          let mark
          if (ex.results_released && ex.type === "mcq") {
            mark = question.submission.mcq_correct
          } else if (!ex.results_released && ex.type === "mcq") {
            mark = "Not marked"
          } else if (ex.type === "pronunciation") {
            if (question.submission !== undefined) mark = this.calculateAverageRating(question.submission.ratings)
            else mark = "No submission"
          }
          moduleRows.push({
            exercise: ex.title,
            question: question.title,
            type: ex.type,
            submission: sub,
            mark: mark
          })
        })
      })
    })
    console.log(moduleRows)
    this.setState({moduleRows, userData})
  };

  calculateAverageRating = (ratingList) => {
    let averageRatings = {
      clarity: 0,
      inotation: 0,
      pronunciation: 0
    }
    if (ratingList.length !== 0) {
      ratingList.forEach(r => {
        let object = JSON.parse(r.rating)
        averageRatings.clarity += parseInt(object.clarity)
        averageRatings.inotation += parseInt(object.inotation)
        averageRatings.pronunciation += parseInt(object.pronunciation)
      })
    }
    averageRatings.clarity = averageRatings.clarity/(ratingList.length)
    averageRatings.pronunciation = averageRatings.pronunciation/(ratingList.length)
    averageRatings.inotation = averageRatings.inotation/(ratingList.length)
    return averageRatings
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  moduleTable = (data) => {
    const columns = [
      {
        title: "Exercise",
        dataIndex: "exercise",
        editable: false,
        key: "exercise",
        render: (text, record) => {
          return (
            <p>{record.exercise}</p>
          )
        }
      },
      {
        title: "Question",
        dataIndex: "question",
        editable: false,
        key: "question",
        render: (text, record) => {
          return (
            <p>{record.question}</p>
          )
        }
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (text, record) => {
          return (
            <div>
              <p>{text}</p>
            </div>
          )
        }
      },
      {
        title: "Submission",
        key: "submission",
        dataIndex: "submission",
        render: (text, record) => {
          if (record.submission) {
            if (record.type === "pronunciation" ) {
              const url = process.env.REACT_APP_ASSET_URL+"/"+record.submission.answer
              return (
                <ReactAudioPlayer src={url} controls />
              )
            } else {
              return <Tag color="purple">MCQ</Tag>
            }
          }
        }
      },
      {
        title: "Mark",
        key: "mark",
        dataIndex: "mark",
        render: (text, record) => {
          if (record.type === "pronunciation") {
            if (record.submission) {
              if (record.submission.ratings) {
              // <p># Times Rated: {record.ratings.length}</p>
                let averageMarks = this.calculateAverageRating(record.submission.ratings)
                return (
                  <div>
                    <p># Times Rated: {record.submission.ratings.length}</p>
                    <p>Clarity: {averageMarks.clarity}</p>
                    <p>Inotation: {averageMarks.inotation}</p>
                    <p>Pronunciation: {averageMarks.pronunciation}</p>
                  </div>
                )
              }
            } else {
              return (
                <Tag color="red">Not Submitted</Tag>
              )
            }
          } else { // mcq question
            if (record.mark === "Not marked") {
              return (
                <p> Not Marked </p>
              )
            } else if (record.mark === "No submission") {
              return (
                <Tag color="red">Not Submitted</Tag>
              )
            } else if (record.mark) {
              return (
                <Tag color="green">Correct</Tag>
              )
            } else if (!record.mark) {
              return (
                <Tag color="red">Incorrect</Tag>
              )
            }
          }
        }
      }
    ]

    return (
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={data}
        pagination={true}
      />
    )
  }

  render() {
    let userDetails
    if (this.state.userData !== undefined) {
      userDetails = (
        <div>
          <p style={{fontSize: 20}}>{this.state.userData.first_name} {this.state.userData.last_name}</p>
          <p style={{fontSize: 20}}>Email: {this.state.userData.email}</p>
        </div>
      )
    }

    return (
      <div>
        {userDetails}
        <Tabs size="large" type="card">
          <Tabs.TabPane key="1" tab="Attempts">
            {this.moduleTable(this.state.moduleRows)}
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="Ratings">
            <StudentRatings />
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }
}

export default CourseStudentView