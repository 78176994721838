import React, { Component } from "react";
import Pronunciation from './Pronunciation'
import HanziWriter from 'hanzi-writer'

class PronunciationHanzi extends Component {

  constructor(props) {
    super(props)
    this.state = {
      character: this.props.selectedQuestion.question_json.hanzi
    }
    console.log(this.props.selectedQuestion)
  }

  componentDidMount = () => {
    this.makeExampleHanzi()
  }

  makeExampleHanzi = () => {
    const example = HanziWriter.create("hanzi-example", this.state.character, {
      width: window.innerHeight*0.2,
      height: window.innerHeight*0.2,
      padding: 5,
    })
  }


  render = () => {
    return (
      <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
        <div>
          <p>Hanzi Character</p>
          <div id="hanzi-example">
          </div>
        </div>
        <Pronunciation selectedQuestion={this.props.selectedQuestion} questionSaved={this.props.questionSaved} submit={this.props.submit}/> 
      </div>
    )
  }

}

export default PronunciationHanzi