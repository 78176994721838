import React, { Component } from "react";

import { Table, Input, Icon, Tag, Button, Divider, notification, Breadcrumb } from "antd";
import { Link } from "react-router-dom"
import Highlighter from "react-highlight-words";
import axios from "axios";

class CourseModules extends Component {
  state = { 
            fetching: true, 
            dataSource: [], 
          };

  componentDidMount = async () => {
    let urlSplit = window.location.href.split("/")
    let courseId = urlSplit[4]
    const {data: dataSource} = await axios.get("courses/get_course_modules/?id="+courseId)
    console.log(dataSource)
    this.setState({ fetching: false, dataSource })
  };

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource;
    return dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
    );
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;
    const columns = [
      {
        title: "LTI ID",
        dataIndex: "resource_link_id",
        key: "resource_link_id",
        editable: false,
        render: (text, record) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Module Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: (text, record) => {
          if (record.module !== undefined && record.module !== null) {
            return record.module.title ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record.module.title.toString()}
              />
            ) : (
              {}
            );
          }
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (text, record) => {
          if (record.module !== undefined && record.module !== null) {
            return (
              <p>{record.module.description}</p>
            )
          }
        }
      },
      {
        title: "Exercises",
        key: "exercsies",
        dataIndex: "exercises",
        render: (text, record) => {
          if (record.module !== undefined && record.module !== null) {
            return (
              <p>{record.module.exercises.length}</p>
            )
          }
        }
      },
      {
        title: "Students Completed",
        key: "studentscompleted",
        dataIndex: "studentscompleted",
        render: (text, record) => {
          return (
            <p>{record.studentscompleted.completed}/{record.studentscompleted.total}</p>
          )
        }
      },
      {
        title: "Submissions w/o Ratings",
        key: "no_submission_ratings",
        dataIndex: "no_submission_ratings",
        render: (text, record) => {
          return (
            <p>{record.swr.count}/{record.swr.total}</p>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          let urlSplit = window.location.href.split("/")
          if (record.module !== undefined && record.module !== null) {
            return (
              <div>
                <Link
                  to={{
                    pathname: `/courses/${urlSplit[4]}/module/${record.module.id}`,
                    query: {
                      exercises: record.module.exercises
                    }
                  }}
                  onClick={() => {
                    this.props.addBreadcrumb(`/courses/${urlSplit[4]}/module/${record.module.id}`, `${record.module.title}`)
                    console.log(record)
                  }}
                >View</Link>
              </div>
            )
          }
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40}}>
            Modules
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            onRow={record => {
              return {
                onDoubleClick: event => { console.log(record) }
              }
            }}
          />

        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        {this.TableView()}
      </div>
    )
  }
}

export default CourseModules