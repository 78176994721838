import React, { Component, Stylesheet } from "react";
import axios from "axios"
import { Modal, Button, Icon, Input, Table, Select, Popconfirm } from "antd";
import Highlighter from "react-highlight-words";

import './asset.css'

import ReactAudioPlayer from "react-audio-player"
import Axios from "axios";

const EditableContext = React.createContext();

class AssetUsers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      userList: [],
      selectedUsers: []
    }
  }

  componentDidMount = async () => {
    const { data: userList } = await axios.get('/auth/get_staff/')
    this.setState({userList})
  }

  closeModal = () => {
    this.props.closeModal()
  }

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource
    return dataSource.filter(
      it =>
        !it.zid || (it.zid && it.zid.toLowerCase().includes(searchText))
        || (it.display_name && it.display_name.toLowerCase().includes(searchText))
    );
  }

  userTable = () => {
    let { searchText, userList } = this.state
    const columns = [
      {
        title: 'zID',
        key: 'zid',
        dataIndex: 'zid',
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (text, record) => {
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={record.display_name.toString()}
            />
          )
        }
      }
    ]

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedUsers: selectedRows })
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div>
        <div style={{paddingBottom: 30}}>
          <Input
            style={{ float: "left", width: "50%",  }}
            placeholder="Search by zID or Name"
            onChange={e => {
              this.setState({ searchText: e.target.value.toLowerCase() });
            }}
            prefix={
              <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
            }
          />
        </div>
       
        <Table
          columns={columns}
          rowSelection={rowSelection}
          dataSource={this.searchEntry(userList, searchText)}
          rowKey={(record) => record.zid}
        />
      </div>

    )
  }

  


  render() {
    return (
      <>
        <Modal
          visible={this.props.visible}
          onOk={() => this.props.onCancel()}
          style={{minWidth: "50%"}}
          closable={false}
          footer={
            (this.state.userList.length > 0) ? (
              <Popconfirm 
                onConfirm={() => this.closeModal()}
                title="Are you sure you want to cancel?"
              >
                <Button type="danger">
                  Cancel
                </Button>
              </Popconfirm>
            ) : (
              <Button type="danger" onClick={() => this.closeModal()}>
                Cancel
              </Button>
            )
          }
        >
          <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
            <div>
              <p style={{fontSize: 24, fontWeight: 'bold'}}>Select Users</p>
              <p>The selected users will have access to the assets with accessibility "specific"</p>
            </div>
            {this.userTable()}
            <Button type="primary"
              onClick={() => {this.props.updateSpecificUserList(this.state.selectedUsers); this.closeModal()}}
            >
              Save "Specific" User List
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AssetUsers
