import React from 'react'
import { Radio, Button } from 'antd'
import ReactAudioPlayer from "react-audio-player"
import axios from 'axios'

class MCQ extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedChoice: null,
      assets: []
    }
  }

  handleChoiceChange = (e) => {
    this.setState({ selectedChoice: e.target.value})
  }
  
  shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  handleSubmitMCQ = async (e) => {
    // get the state and submit it.
    // only need the meta data and the answer
    const formData = new FormData()
    let userId = sessionStorage.userId
    let urlSplit = window.location.href.split('/')
    let course = urlSplit[4]
    let module = urlSplit[6]
    let exercise = urlSplit[8]
    let type = this.props.selectedQuestion.type
    formData.append('meta', JSON.stringify({
      courseId: course,
      userId: userId,
      moduleId: module,
      exerciseId: exercise,
      questionId: this.props.selectedQuestion.id,
      type: type
    }))
    formData.append('answer', this.state.selectedChoice)
    const response = await axios.post("submissions/submissions/", formData)
    if (response.status === 200) {
      this.props.questionSaved(response.data)
    }
  }

  componentDidMount = () => {
    let shuffled = this.shuffle(this.props.selectedQuestion.assets)
    this.setState({ assets: shuffled })
  }

  renderChoices = (assets) => {
    let choices = assets.map((asset) => {
      let url = process.env.REACT_APP_ASSET_URL+"/"+asset.s3_link
        if (asset.type === "picture") {
          return (
            <Radio style={{display: "block", padding: 20}} value={asset.id}>
              <img width="200" src={url} />
            </Radio>
          )
        } else return (
          <Radio style={{display: "block", padding: 20}} value={asset.id} style={{paddingTop: 50}}>
            <ReactAudioPlayer
              src={url}
              controls
            />
          </Radio>
        )
    })
    return choices
  }

  render = () => {
    const selectedQuestion = this.props.selectedQuestion
    
    let questionData = (
      <div style={{display: 'flex', flexDirection: "column"}}>
        <Radio.Group style={{paddingBottom: 30}} onChange={this.handleChoiceChange}>
          {this.renderChoices(this.state.assets)}
        </Radio.Group>
        { this.props.submit ? (
          <Button type="primary" onClick={() => {this.handleSubmitMCQ()}}>
            Submit
          </Button>
        ) : (null)}
      </div>
    )

    return (
      <div>
        {questionData}
      </div>
    )
  }
}

export default MCQ