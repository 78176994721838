import React, { Component } from "react";

import { Table, Input, Icon, Tag, Button, Divider, notification, Popconfirm, Switch } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";

import ModuleForm from './ModuleForm'
import SingleModuleView from './SingleModuleView.js'
import { Route } from 'react-router-dom'

class ModuleView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true, 
      dataSource: [], 
      visibleModuleForm: false,
      visibleModuleModal: false,
      currentModule: undefined,
      exerciseData: undefined,
    }
  }

  componentDidMount = async () => {
    // const { data: dataSource } = await axios.get("modules/get_all_modules/");
    // const exercises = await axios.get("exercises/get_exercises/")
    const { data: response } = await axios.get("modules/module_view/")
    this.setState({ dataSource: response.moduleData, exerciseData: response.exerciseData, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  handleOnAddModule = async (course, props) => {
    const { currentModule } = this.state;
    const newModule = { ...currentModule, ...course };
    this.setState({ fetching: true });

    try {
      const { data } = currentModule
        ? await axios.put("modules/modules/", newModule)
        : await axios.post("modules/modules/", newModule);
      const index = this.state.dataSource.findIndex(
        record => record.id === data.id
      );
      props.resetFields();
      if (currentModule) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, data]
          : [
              ...this.state.dataSource.slice(0, index),
              data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        visibleModuleForm: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource;
    return dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
        || (it.description && it.description.toLowerCase().includes(searchText))
    );
  }

  handleOnDeleteModule = async id => {
    this.setState({ fetching: true });
    try {
      await axios.delete("modules/modules", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        record => record.id !== id
      );

      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;
    const columns = [
      {
        title: "Module Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Linked",
        key: "linked",
        dataIndex: "linked",
        render: (text, record) => {
          let tag
          if (record.lti !== null) {
            tag = (<Tag color="green">Linked</Tag>)
          } else {
            tag = (<Tag color="red">Not Linked</Tag>)
          }
          return (
            <div>
              { tag }
            </div>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          let unlinked = true
          let dashLink = "/"
          if (record.lti !== null) {
            unlinked = false
            dashLink = `/courses/${record.lti.course}/module/${record.id}`
          }
          return (
            <div>
              <Route render={({history}) => (
                <Button disabled={unlinked} type="link"
                  onClick={() => {
                    history.push({
                      pathname: dashLink
                    })
                  }}
                >
                  Open Dashboard
                </Button>
              )}>
              </Route>
             
              <Divider type="vertical" />
              <Button type="link"
                onClick={() => {
                  this.setState({
                    currentModule: record,
                    visibleModuleForm: true
                  })
                }}
              >
                Edit
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to delete the module? This will affect courses linked."
                onConfirm={() => {this.handleOnDeleteModule(record.id)}}
              >
              <Button type="link">
                Delete
              </Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40 }}>
            Modules
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title or Description"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
            <Button
              style={{ marginLeft: "10px", float: "right" }}
              type="primary"
              onClick={() => {
                this.setState({
                  visibleModuleForm: true,
                  currentModule: undefined
                });
              }}
            >
              Create New
            </Button>
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={{ position: 'both', defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          />
          <SingleModuleView 
            visible={this.state.visibleModuleModal}
            onCancel={() => this.setState({ visibleModuleModal: false })}
            module={this.state.currentModule}
          />
          <ModuleForm
            moduleTitle="Create module"
            handleOnAddModule={this.handleOnAddModule} // if it actualy gets added.
            visible={this.state.visibleModuleForm}
            selectedModule={this.state.currentModule} // in case of edit form.
            onCancel={() => this.setState({ visibleModuleForm: false })}
            exercises={this.state.exerciseData}
          />
        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        {this.TableView()}
      </div>
    )
  }
}

export default ModuleView