import React, { Component } from "react";

import { Table, Input, Icon, Tag, Button, Divider, notification, Breadcrumb } from "antd";
import { Link } from "react-router-dom"
import Highlighter from "react-highlight-words";
import axios from "axios";

class CoursesView extends Component {
  state = { 
            fetching: true, 
            dataSource: [], 
          };

  componentDidMount = async () => {
    const {data: dataSource} = await axios.get("courses/get_all_courses/")
    console.log(dataSource)
    this.setState({ fetching: false, dataSource })
  };

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource;
    return dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
        || (it.code && it.code.toLowerCase().includes(searchText))
    );
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  handleOnAddExercie = async (course, props) => {
    const { currentExercise } = this.state;
    console.log(currentExercise)
    const newExercise = { ...currentExercise, ...course };
    this.setState({ fetching: true });

    try {
      const { data } = currentExercise
        ? await axios.put("exercises/exercises/", newExercise)
        : await axios.post("exercises/exercises/", newExercise);
      const index = this.state.dataSource.findIndex(
        record => record.id === data.id
      );
      props.resetFields();
      if (currentExercise) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, data]
          : [
              ...this.state.dataSource.slice(0, index),
              data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        visibleExerciseForm: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  }

  handleOnDeleteExercise = async id => {
    this.setState({ fetching: true });
    try {
      await axios.delete("exercises/exercises", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        record => record.id !== id
      );

      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;
    const columns = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        editable: false,
        render: (text, record) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Course Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: text => {
          return (
            <p>{text}</p>
          );
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          return (
            <div>
              <Link
                to={{
                  pathname: `/courses/${record.id}`,
                }}
                onClick={() => {this.props.addBreadcrumb(`/courses/${record.id}/`, `${record.code}`)}}
              >View</Link>
              <Divider type="vertical"/>
              <Button
                type="link"
                onClick={() => {window.open(record.link)}}
              >
                LMS
              </Button>
            </div>
          )
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40}}>
            Courses
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={{ position: 'both', defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          />

        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        {this.TableView()}
      </div>
    )
  }
}

export default CoursesView