import React, { Component } from "react";

import {
  Form,
  Input,
  Button,
  Drawer,
  Col,
  Row,
  Popconfirm,
  Tooltip,
  Select
} from "antd";

import axios from "axios"
const { Option } = Select;
const { TextArea } = Input;

class ExerciseForm extends Component {
  state = {
    questions: [],
    exerciseType: undefined,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleOnAddExercise({ ...values }, this.props.form);
      }
    });
  };

  componentDidMount = async () => {
    const questionData = await axios.get("questions/get_questions")
    this.setState({ questions: questionData.data })
  }

  handleTypeSelect = (e) => {
    this.setState({exerciseType: e})
  }

  parseInitialQuestions = (questions) => {
    console.log("here")
    let questionIndexes = []
    questions.forEach(q => {
      questionIndexes.push(q.id)
    })
    return questionIndexes
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedExercise } = this.props
    let types = [
      {
        title: "pronunciation"
      },
      {
        title: "mcq"
      },
      {
        title: "text"
      },
      {
        title: "hanzi"
      }
    ]

    let questionsComponent
    if (this.state.questions !== undefined) {
      questionsComponent = (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Questions">
              {getFieldDecorator("questions", {
                initialValue: selectedExercise
                    ? this.parseInitialQuestions(selectedExercise.questions)
                    : [],
                rules: [{ required: false, message: "Please choose some questions" }]
              })(
                <Select mode="multiple">
                  {this.state.questions.map((question, i) => {
                    if (this.state.exerciseType !== undefined) {
                      // then filter the questions by type.
                      if (question.type === this.state.exerciseType || question.type.includes(this.state.exerciseType)) {
                        return (
                          <Option key={question.id} value={question.id}>
                            {question.title}
                          </Option>
                        )
                      }
                    } else {
                      return (
                        <Option key={question.id} value={question.id}>
                          {question.title}
                        </Option>
                      )
                    }
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      )
    }

    let pronunciationFields
    if (this.state.exerciseType === "pronunciation") {
      pronunciationFields = (
        <>
          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Minimum Reviews</p>
                <Tooltip title="If it is a pronunciation exercise, this is the minimum reviews required per question">
                  <a>?</a>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("min_reviews", {
                  initialValue: selectedExercise
                    ? selectedExercise.min_reviews
                    : "",
                  rules: [{ required: true, message: "Please enter minimum reviews" }]
                })(<Input placeholder="Please enter minimum reviews" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Minimum Reviews Successful</p>
                <Tooltip title="???">
                  <a>?</a>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item label="Minimum Reviews Successful">
                {getFieldDecorator("min_reviews_success", {
                  initialValue: selectedExercise
                    ? selectedExercise.min_reviews_success
                    : "",
                  rules: [{ required: true, message: "Please enter minimum reviews successful" }]
                })(<Input placeholder="Please enter minimum reviews successful" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Maximum Reviews</p>
                <Tooltip title="This is the maxmimum amount of reviews a student will have to complete">
                  <a>?</a>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item label="Maximum Reviews Per Student">
                {getFieldDecorator("max_reviews_per_student", {
                  initialValue: selectedExercise
                    ? selectedExercise.max_reviews_per_student
                    : "",
                  rules: [{ required: true, message: "Please enter the maximum reviews per student" }]
                })(<Input placeholder="Please enter the maximum reviews per student" />)}
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    }

    return (
      <Drawer
        title="Create/Edit Exercise"
        width={720}
        closable={false}
        onClose={this.onClose}
        visible={this.props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
      >
        <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Exercise Title</p>
                <Tooltip title="The exercise title">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("title", {
                  initialValue: selectedExercise
                    ? selectedExercise.title
                    : "",
                  rules: [{ required: true, message: "Please enter the exercise title" }]
                })(<Input placeholder="Please enter the exercise title" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Description</p>
                <Tooltip title="A brief description of the exercise (e.g. What it tests)">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("description", {
                  initialValue: selectedExercise
                    ? selectedExercise.description
                    : "",
                  rules: [{ required: true, message: "Please enter the exercise description" }]
                })(<TextArea placeholder="Please enter the exercise description" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Type</p>
                <Tooltip title="The exercise type (will it be mcq questions, or pronunciation questions)">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("type", {
                  initialValue: selectedExercise
                    ? selectedExercise.type
                    : [],
                  rules: [{ required: true, message: "Please enter the exercise type" }]
                })(
                <Select onSelect={(e) => {this.handleTypeSelect(e)}}>
                  {types.map((type, i) => (
                    <Option key={i} value={type.title}>
                      {type.title}
                    </Option>
                  ))}
                </Select>)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Rating Scale</p>
                <Tooltip title="The weight of each questions (how many marks)">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("rating_scale", {
                  initialValue: selectedExercise
                    ? selectedExercise.rating_scale
                    : "",
                  rules: [{ required: true, message: "Please enter the rating scale" }]
                })(<Input placeholder="Please enter the rating scale" />)}
              </Form.Item>
            </Col>
          </Row>

          {pronunciationFields}

          {questionsComponent}

          <Form.Item wrapperCol={{ offset: 16 }}>
            <Popconfirm
              title="Changes will be discarded."
              onConfirm={() => this.props.onCancel()}
            >
              <Button style={{ marginRight: "10px" }} type="danger">
                Cancel
              </Button>
            </Popconfirm>
            <Button type="submit" htmlType="submit" style={{background: "#1890ff", color: "white"}}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create({ name: "register" })(ExerciseForm);

//https://api.stage.cdf.teaching.unsw.edu.au/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}

//http://localhost:5000/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}&TotalCost=${e://Field/TotalCost}
