import React, { Component } from "react";
import axios from "axios"
import CoursesView from "./CoursesView.js"
import { notification, Breadcrumb, Button } from "antd";
import SingleCourseView from './SingleCourseView.js'
import CourseStudentView from './CourseStudentView.js'
import CourseModuleView from './CourseModuleView.js'
import { Route, Switch, Link } from 'react-router-dom'
import CourseExercise from "./CourseExercise.js"

class CourseDashboard extends Component {
  state = { 
            fetching: true, 
            dataSource: [], 
            breadcrumbs: [{link: '/courses/', title: 'All Courses'}],
            backDisabled: false,
            backLink: "",
          };

  componentDidMount = async () => {
    // check url to setup the breadcrumbs.
    let breadcrumbs
    let urlSplit = window.location.href.split("/")
    // if urlSplit has 5 elements, then its just courses.
    // fix this up later.
    let courseId, moduleId, exerciseId, studentId
    // const data = await axios.get(``)
    if (urlSplit.length >= 5) {
      courseId = urlSplit[4]
    }
    if (urlSplit.length >= 7) {
      if (urlSplit[5] == "module") moduleId = urlSplit[6]
      else studentId = urlSplit[6]
    }
    if (urlSplit.length >= 9) {
      exerciseId = urlSplit[8]
    }
    const { data: titles } = await axios.get(`/submissions/get_breadcrumb_titles/?moduleId=${moduleId}&exerciseId=${exerciseId}&courseId=${courseId}`)
    if (titles.details.course_title) {
      this.addBreadcrumb(`/courses/${courseId}`, titles.details.course_title)
    }
    if (titles.details.module_title) {
      this.addBreadcrumb(`/courses/${courseId}/module/${moduleId}`, titles.details.module_title)
    }
    if (titles.details.exercise_title) {
      this.addBreadcrumb(`/courses/${courseId}/module/${moduleId}/exercise/${exerciseId}`, titles.details.exercise_title)
    }
    if (studentId) {
      this.addBreadcrumb(`/courses/${courseId}/student/${studentId}`, studentId)
    }
    // if (urlSplit.length > 6) {
    //   if (urlSplit[5] === "student") {
    //     breadcrumbs.push({link: `/courses/${urlSplit[4]}/student/${urlSplit[6]}`, title: `Student - ${urlSplit[6]}`})
    //   } else {
    //     breadcrumbs.push({link: `/courses/${urlSplit[4]}/module/${urlSplit[6]}`, title: `Module - ${urlSplit[6]}`})
    //   }
    // }
  };

  addBreadcrumb = (link, title) => {
    let current = this.state.breadcrumbs
    let backLink = current[current.length - 1].link
    current.push({link, title})
    this.setState({ breadcrumbs: current })
    if (current.length > 1) {
      this.setState({ backDisabled: false, backLink })
    } else {
      this.setState({ backDisabled: true })
    }
  }

  removeBreadcrumb = (pos) => {
    let current = this.state.breadcrumbs.splice(0, pos+1)
    this.setState({ breadcrumbs: current })
    if (current.length > 1) {
      this.setState({ backDisabled: false, backLink: current[pos-1].link })
    } else {
      this.setState({ backDisabled: true })
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  render() {
    let breadcrumbs = this.state.breadcrumbs.map((crumb, i) => {
      return (
        <Breadcrumb.Item key={i}>
          <Link to={crumb.link} style={{fontSize: 20}} onClick={() => {this.removeBreadcrumb(i)}}>{crumb.title}</Link>
        </Breadcrumb.Item>
      )
    })
    return (
      <div>
        {/* <Link
          to={this.state.backLink}
          onClick={() => {this.removeBreadcrumb(this.state.breadcrumbs.length-1)}}
          disabled={this.state.backDisabled}
        >
          <ArrowLeftOutlined />
        </Link> */}
        <Breadcrumb style={{paddingBottom: 20, paddingTop: 20}}>
          {breadcrumbs}
        </Breadcrumb>
        <Switch>
          <Route exact path="/courses" render={props => <CoursesView addBreadcrumb={this.addBreadcrumb}/>} />
          <Route path={`/courses/:id/module/:mid/exercise/:eid`} render={props => <CourseExercise addBreadcrumb={this.addBreadcrumb} />} />
          <Route path={`/courses/:id/student/:sid`} render={props => <CourseStudentView addBreadcrumb={this.addBreadcrumb} />} />
          <Route path={`/courses/:id/module/:mid`} render={props => <CourseModuleView addBreadcrumb={this.addBreadcrumb} />} />
          <Route path={`/courses/:id`} render={props => <SingleCourseView addBreadcrumb={this.addBreadcrumb} />} />
        </Switch>
      </div>
    )
  }
}

export default CourseDashboard