import React, { Component } from "react";

import { Table, Input, Icon, Tag, Button, Divider, notification, Tabs, Breadcrumb  } from "antd";
import { Route, Switch, Link } from "react-router-dom"
import Highlighter from "react-highlight-words";
import axios from "axios";
import Exercise from './Exercise.js'
const { TabPane } = Tabs

class ExerciseTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      searchText: "",
      dataSource: this.props.exercises
    }
  }

  componentDidMount = async () => {
    // let id = this.props.location.state.selectedModule.id
    // const { data: dataSource } = await axios.get("modules/modules/?id=" + id);
    this.setState({ fetching: false })
  };

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource;
    return dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
    );
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;
    const columns = [
      {
        title: "Exercise Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (text, record) => {
          return (
            <div>
              {text}
            </div>
          )
        }
      },
      {
        title: "Questions",
        key: "questions",
        dataIndex: "questions",
        render: (text, record) => {
          let questionCount = record.questions.length
          return (
            <div>
              {questionCount}
            </div>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          let urlSplit = window.location.href.split("/")
          let moduleId = urlSplit[6]
          return (
            <div>
              <Link
                to={{
                  pathname: `/courses/${urlSplit[4]}/module/${moduleId}/exercise/${record.id}`,
                  state: {
                    exercise: record
                  }
                }}
                onClick={() => {this.props.addBreadcrumb(`/courses/${urlSplit[4]}/module/${moduleId}/exercise/${record.id}`, `${record.title}`)}}
              >View</Link>
            </div>
          )
        }
      }
    ]
    return (
      <>
        <div style={{ width: "100%" }}>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
          />
        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        { this.TableView() }
      </div>
    )
  }
}

export default ExerciseTable