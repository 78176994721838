import React, { Component } from "react";
import { Form, Button, Row, Col, Card, Select, notification } from "antd";
import { Redirect } from "react-router-dom"
import axios from "axios";
import queryString from "query-string"
import ModuleForm from './ModuleForm'

const { Option } = Select

class LinkModule extends Component {

  constructor(props) {
    super(props)
    this.state = {
      visibleModuleForm: false,
      exerciseData: undefined,
      fetching: true,
      moduleId: undefined,
      moduleList: undefined,
      redirect: false,
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  componentDidMount = async () => {
    // get list of modules.
    const {data: moduleList} = await axios.get("modules/get_unlinked_modules/")
    const {data: exerciseData} = await axios.get("exercises/get_exercises/")
    this.setState({ fetching: false, moduleList: moduleList, exerciseData })
    if (this.props.hideSideBar !== undefined) {
      // this.props.hideSideBar()
    }
  }

  handleOnAddModule = async (data, props) => {
    try {
      const { data: dataSource } = await axios.post("modules/modules/", data);
      this.openNotificationWithIcon("success", "Record created successfully");
      // also add this to the list of modules.
      let modules = this.state.moduleList
      modules.push(dataSource)
      this.setState({ moduleList: modules, visibleModuleForm: false, moduleId: dataSource.id })
      this.linkModule()
    } catch (error) {
      this.openNotificationWithIcon("error", "An error occurred, please try again later", error.message)
    }
    
  }

  linkModule = async () => {
    // get the module id from state and use this to submit a lti module linking to a module
    let values = queryString.parse(window.location.href)
    let ltiModule = values.lti
    const { data: linkModule } = await axios.post("modules/link_module/", { moduleId: this.state.moduleId, ltiId: ltiModule })
    // now that it's linked go to the module page
    this.setState({ redirect: true })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.linkModule()
  }

  handleOnChange = (e) => {
    this.setState({ moduleId: e })
  }

  render() {
    if (this.state.redirect) {
      let values = queryString.parse(window.location.href)
      let link = `/courses/${values.courseId}/module/${this.state.moduleId}`
      this.props.showSideBar()
      return(
        <Redirect to={link} />
      )
    }
    
    if (!this.state.fetching) {
      return (
        <div style={{flex: 1, flexDirection: "column", alignItems:'center', justifyContent: "center", display: "flex", height: "100%"}}>
          <Card style={{width: "50%", height: "50vh"}}>
            <p> Please select one of the Modules to link with the LTI Module </p>
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Modules">
                    <Select showSearch={true} onChange={(e) => this.handleOnChange(e)}>
                      {this.state.moduleList.map((module, i) => {
                        return (
                        <Option key={i} value={module.id}>
                          {module.title}
                        </Option>
                      )})}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item wrapperCol={{ offset: 16 }}>
                <Button
                  onClick={() => this.setState({ visibleModuleForm: true })}
                  style={{marginRight: 20}}
                >
                  Create New Module
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
            </Form.Item>
            </Form>
          </Card>
          <ModuleForm
            moduleTitle="Create module"
            handleOnAddModule={this.handleOnAddModule} // if it actualy gets added.
            visible={this.state.visibleModuleForm}
            onCancel={() => this.setState({ visibleModuleForm: false })}
            exercises={this.state.exerciseData}
          />
        </div>
      )
    } else return (<div></div>)
  }
}

export default LinkModule
