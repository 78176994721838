import React, { Component } from "react";

import { Collapse, Table, Tag } from "antd";
import { Route, Switch, Link } from "react-router-dom"
import Highlighter from "react-highlight-words";
import ReactAudioPlayer from "react-audio-player";

class MarkTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      searchText: "",
    }
  }

  componentDidMount = async () => {
    // let id = this.props.location.state.selectedModule.id
    // first i need to get all the ratings done by this student for this course/module/exercise

    // const { data: dataSource } = await axios.get("modules/modules/?id=" + id);
    this.setState({ fetching: false })
  };

  pronunciationRatingTable = (ratings) => {
    const columns = [
      {
        title: "Rated By",
        dataIndex: "rated_by",
        editable: false,
        key: "rated_by",
        render: (text, record) => {
          let urlSplit = window.location.href.split("/")
          let course = urlSplit[4]
          return (
            // <Link to={`/courses/${course}/student/${record.rater_id}`}>{record.rater_id}</Link>
            <p>{record.rater_id}</p>
          )
        }
      },
      {
        title: "Rating",
        key: "rating",
        dataIndex: "rating",
        render: (text, record) => {
          if (record.rating) {
            // <p># Times Rated: {record.ratings.length}</p>
            let object = JSON.parse(record.rating)
            return (
              <div>
                <p>Clarity: {object.clarity}</p>
                <p>Inotation: {object.inotation}</p>
                <p>Pronunciation: {object.pronunciation}</p>
              </div>
            )
          } else {
            return (
              <div>
                Has not been rated yet.
              </div>
            )
          }
        }
      }
    ]

    return (
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={ratings}
        pagination={false}
      />
    )
  }

  mcqMarkTable = () => {
    if (this.props.exerciseData !== undefined) {
      const columns = [
        {
          title: "Question Title",
          dataIndex: "title",
          editable: false,
          key: "title",
          render: (text, record) => {
            return (
              <p>{record.title}</p>
            )
          }
        },
        {
          title: "Correctness",
          key: "mcq_correct",
          dataIndex: "mcq_correct",
          render: (text, record) => {
            if (record.submission.mcq_correct) {
              return (
                <Tag color="green">Correct</Tag>
              )
            } else {
              return (
                <Tag color="red">Incorrect</Tag>
              )
            }
          }
        }
      ]
  
      return (
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={this.props.exerciseData.questions}
          pagination={false}
        />
      )
    }
  }

  finalMark = (ratingList) => {
    let averageRatings = {
      clarity: 0,
      inotation: 0,
      pronunciation: 0
    }
    let numberOfRatings = 0
    if (ratingList !== undefined && ratingList.length !== 0) {
      ratingList.forEach(r => {
        let object = JSON.parse(r.rating)
        averageRatings.clarity += parseInt(object.clarity)
        averageRatings.inotation += parseInt(object.inotation)
        averageRatings.pronunciation += parseInt(object.pronunciation)
      })
      averageRatings.clarity = averageRatings.clarity/(ratingList.length)
      averageRatings.pronunciation = averageRatings.pronunciation/(ratingList.length)
      averageRatings.inotation = averageRatings.inotation/(ratingList.length)
      numberOfRatings = ratingList.length
    }
    return (
      <div style={{paddingTop: 30}}>
        <p style={{fontSize: 20}}>Final marks:</p>
        <p>Clarity: {averageRatings.clarity}</p>
        <p>Pronunciation: {averageRatings.pronunciation}</p>
        <p>Inotation: {averageRatings.inotation}</p>
        <p># of ratings: {numberOfRatings}</p>
      </div>
    )
  }

  renderCard = () => {
    if (this.props.exerciseData !== undefined) {
      let questions = this.props.exerciseData.questions
      return questions.map((q, i) => {
        let answer
        if (q.submission !== undefined) {
          if (q.type === "pronunciation") {
            let url = process.env.REACT_APP_ASSET_URL+"/"+q.submission.answer
            return (
              <Collapse key={i}>
                <Collapse.Panel header={q.title}>
                  <p> Q: {q.question_text} </p>
                  <div>
                    <p>A: </p>
                    <ReactAudioPlayer src={url} controls />
                    {this.pronunciationRatingTable(q.submission.ratings)}
                    {this.finalMark(q.submission.ratings)}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )
          }
        }
      })
    }
  }

  render() {
    // for each question generate a card
    if (this.props.exerciseData != undefined && this.props.exerciseData.type === "pronunciation") {
      return (
        <div>
          {this.renderCard()}
        </div>
      )
    } else {
      return (
        <div>
          {this.mcqMarkTable()}
        </div>
      )
    }
  }
}

export default MarkTable