import React, { Component } from "react";
import { Modal, Button, Radio, Popconfirm, notification, Spin } from "antd";
import ReactAudioPlayer from "react-audio-player"
import MicRecorder from "mic-recorder-to-mp3"
import { AudioOutlined, StopFilled } from "@ant-design/icons"
import AudioAnalyser from './Audio/AudioAnalyser'
import axios from "axios";

import Pronunciation from './Questions/Pronunciation' 
import MCQ from './Questions/MCQ'
import HanziLearning from "./Questions/HanziLearning";
import HanziQuiz from "./Questions/HanziQuiz"
import PronunciationHanzi from './Questions/PronunciationHanzi'

class QuestionView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedChoice: undefined,
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  handleChoiceChange = (e) => {
    this.setState({ selectedChoice: e.target.value})
  }

  closeModal = () => {
    // clear audio recording before closing the window
    if (this.state.record) {
      this.stopRecording()
    }
    if (this.state.recordingBlob) {
      this.setState({ recordingBlob: undefined })
    }
    this.props.onCancel()
  }

  render() {
    const selectedQuestion = this.props.selectedQuestion
    let information, questionData

    if (selectedQuestion !== undefined) {
      // every question will have a title and question text. 
      let question_text = selectedQuestion.question_text
      if (question_text === undefined || question_text === "" || question_text === null || question_text === " ") {
        question_text = "Please pronounce the following word - " + selectedQuestion.title + ". Samples are provided below to listen and repeat."
      }
      information = (
        <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding: 30}}>
          <p>{selectedQuestion.title}</p>
          <p>Q: {question_text}</p>
        </div>
      )

      if (selectedQuestion.type === "pronunciation") {
        questionData = <Pronunciation selectedQuestion={selectedQuestion} questionSaved={this.props.questionSaved} submit />
      } else if (selectedQuestion.type === "mcq") {
        questionData = <MCQ selectedQuestion={selectedQuestion} questionSaved={this.props.questionSaved} submit />
      } else if (selectedQuestion.type === "hanzi-learning") {
        questionData = <HanziLearning selectedQuestion={selectedQuestion} questionSaved={this.props.questionSaved} submit />
      } else if (selectedQuestion.type === "hanzi-quiz") {
        questionData = <HanziQuiz selectedQuestion={selectedQuestion} questionSaved={this.props.questionSaved} submit />
      } else if (selectedQuestion.type === "pronunciation-hanzi") {
        questionData = <PronunciationHanzi selectedQuestion={selectedQuestion} questionSaved={this.props.questionSaved} submit />
      }
    }
    // The rest depends on what type of question it is.
    return (
      <>
        <Modal
          visible={this.props.visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.closeModal()}
          style={{minWidth: "50%"}}
          footer={[
            <Button key="1" onClick={() => this.closeModal()}>
              OK
            </Button>
          ]}
        >
          <div>
            {information}
            {questionData}
          </div>
        </Modal>
      </>
    );
  }
}

export default QuestionView;