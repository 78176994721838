import React, { Component } from "react";

import { Table, Input, Icon, Popconfirm, Button, Divider, notification, Radio } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import ExerciseForm from './ExerciseForm'
import DuplicateExercise from "./DuplicateExercise";
import ExercisePreview from './ExercisePreview'
class ExercisesView extends Component {
  state = { 
            fetching: true, 
            dataSource: [], 
            visibleExerciseForm: false,
            visibleExercisePreview: false,
            currentExercise: undefined,
            questions: undefined,
            filter: "no_filter"
          };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("exercises/get_exercises/");
    this.setState({ dataSource, fetching: false });
  };

  searchEntry(dataSource, searchText, filter) {
    let searchBar
    if (!searchText) searchBar = dataSource;
    else 
      searchBar = dataSource.filter(
        it =>
          !it.title || (it.title && it.title.toLowerCase().includes(searchText))
          || (it.description && it.description.toLowerCase().includes(searchText))
      );
    if (filter === "no_filter") {
      return searchBar
    } else {
      return searchBar.filter(it => (it.type === filter))
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  handleOnAddExercie = async (course, props) => {
    const { currentExercise } = this.state;
    console.log(currentExercise)
    const newExercise = { ...currentExercise, ...course };
    this.setState({ fetching: true });

    try {
      const { data } = currentExercise
        ? await axios.put("exercises/exercises/", newExercise)
        : await axios.post("exercises/exercises/", newExercise);
      const index = this.state.dataSource.findIndex(
        record => record.id === data.id
      );
      props.resetFields();
      if (currentExercise) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, data]
          : [
              ...this.state.dataSource.slice(0, index),
              data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        visibleExerciseForm: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  }

  handleError = (error) => {
    if (error.response) {
      this.openNotificationWithIcon(
        "error",
        "An error occurred, please try again later",
        error.response.data.message
      );
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request);
      this.openNotificationWithIcon(
        "error",
        "An error occurred, please try again later",
        error.request
      );
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log(
        "Error",
        "An error occurred, please try again later",
        error.message
      );
      this.openNotificationWithIcon(
        "error",
        "An error occurred, please try again later",
        error.message
      );
    }
    console.log(error);
  }

  handleOnDeleteExercise = async id => {
    this.setState({ fetching: true });
    try {
      await axios.delete("exercises/exercises", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        record => record.id !== id
      );

      this.setState({ dataSource, fetching: false });
    } catch (error) {
      this.handleError(error)
      this.setState({ fetching: false });
    }
  };

  duplicateExercise =  async (exercise, title, description) => {
    this.setState({ fetching: true })
    let object = {
      id: exercise.id,
      title: title,
      description: description
    }
    try {
      const { data: exercise } = await axios.post("exercises/duplicate_exercise/", object)
      this.openNotificationWithIcon("success", "Exercise has been duplicated")
      // add this to the table.
      let exerciseData = this.state.dataSource
      exerciseData.push(exercise)
      this.setState({ dataSource: exerciseData, currentExercise: undefined, visibleDuplicateModal: false })
    } catch (err) {
      this.handleError(err)
    }
    this.setState({ fetching: false })
  }

  filterRadio = (e) => {
    this.setState({ filter: e.target.value })
  }

  TableView = () => {
    const { dataSource, fetching, searchText, filter } = this.state;
    const columns = [
      {
        title: "Exercise Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: text => {
          return (
            <p>{text}</p>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          return (
            <div>
               <Button type="link"
                onClick={() => {
                  this.setState({ visibleExercisePreview: true, currentExercise: record })
                }}
              >
                View
              </Button>
              <Divider type="vertical" />
              <Button type="link"
                onClick={() => {
                  this.setState({ visibleExerciseForm: true, currentExercise: record })
                }}
              >
                Edit
              </Button>
              <Divider type="vertical" />
              <Button type="link"
                onClick={() => {
                  this.setState({ visibleDuplicateModal: true, currentExercise: record})
                }}
              >
                Duplicate
              </Button>
              <Popconfirm
                title="Are you sure you want to delete this exercise? Modules including this will be affected."
                onConfirm={() => {this.handleOnDeleteExercise(record.id)}}
              >
              <Button type="link">
                Delete
              </Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40}}>
            All Exercises
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
            <Button
              style={{ marginLeft: "10px", float: "right" }}
              type="primary"
              onClick={() => {
                this.setState({
                  visibleExerciseForm: true,
                  currentExercise: undefined
                });
              }}
            >
              Create New
            </Button>
          </div>

          <div style={{width: "70%", display: "flex", flex: 1, paddingBottom: 20, flexDirection: "column"}}>
            <p style={{paddingRight: 30}}>Filter by Type</p>
            <Radio.Group onChange={this.filterRadio} defaultValue="no_filter">
              <Radio.Button value="no_filter">No filter</Radio.Button>
              <Radio.Button value="pronunciation">Pronunciations</Radio.Button>
              <Radio.Button value="mcq">MCQ</Radio.Button>
            </Radio.Group>
          </div>

          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            pagination={{ position: 'both', defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
            dataSource={this.searchEntry(dataSource, searchText, filter)}
          />

        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        {this.TableView()}
        <ExerciseForm
          visible={this.state.visibleExerciseForm}
          handleOnAddExercise={this.handleOnAddExercie}
          selectedExercise={this.state.currentExercise}
          onCancel={() => {this.setState({ visibleExerciseForm: false, currentExercise: undefined })}}
        />
        <DuplicateExercise
          visible={this.state.visibleDuplicateModal}
          selectedExercise={this.state.currentExercise}
          onCancel={() => {this.setState({ visibleDuplicateModal: false, currentExercise: undefined })}}
          duplicateExercise={this.duplicateExercise}
        />
        <ExercisePreview 
          visible={this.state.visibleExercisePreview}
          selectedExercise={this.state.currentExercise}
          onCancel={() => {this.setState({ visibleExercisePreview: false, currentExercise: undefined})}}
        />
      </div>
    )
  }
}

export default ExercisesView