import React, { Component } from "react";

import { Table, Tag, Button, Divider, notification, Tabs, Popconfirm  } from "antd";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom"
import axios from "axios";

class CourseModuleView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      moduleData: undefined,
      exerciseData: undefined,
    }
  }

  exportCSV = async () => {
    console.log(this.state)
    let moduleId = this.state.moduleData.Module.id
    const response = await axios.get(`/modules/export_marks/?moduleId=${moduleId}`)
    let a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([response.data]), {
      type: response.headers["content-type"]
    });
    const fileName = "module_"+moduleId+"_marks.csv";
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  
  }

  componentDidMount = async () => {
    // let id = this.props.location.state.selectedModule.id
    let urlSplit = window.location.href.split("/")
    let moduleId = urlSplit[6]
    const { data: moduleData } = await axios.get("modules/modules/?id="+moduleId)
    // remove the latest breadcrumb if it's already been made for modules
    // this.props.addBreadcrumb(`/courses/${moduleData.LTI.course}/module/${moduleData.Module.id}`, moduleData.Module.title)
    // const { data: dataSource } = await axios.get("modules/modules/?id=" + id);
    this.setState({ fetching: false, moduleData, exerciseData: moduleData.Module.exercises })
    console.log(moduleData)
  };

  searchEntry(dataSource, searchText) {
    if (!searchText) return dataSource;
    return dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
    );
  }

  releaseMarks = async (e) => {
    this.setState({ fetching: true })
    const data = await axios.put("/exercises/release_results/", { "id": e.id })
    if (data.status === 200) {
      // do something
      console.log(data)
      // now update the status
      // find the right index
      const index = this.state.exerciseData.findIndex(
        record => record.id === e.id
      );
      const dataSource =
        index < 0
          ? [...this.state.exerciseData, data]
          : [
              ...this.state.exerciseData.slice(0, index),
              data.data,
              ...this.state.exerciseData.slice(index + 1)
            ];
      this.setState({ fetching: false, exerciseData: dataSource })
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  TableView = () => {
    const { exerciseData, fetching, searchText } = this.state;
    const columns = [
      {
        title: "Exercise Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (text, record) => {
          return (
            <div>
              {text}
            </div>
          )
        }
      },
      {
        title: "Questions",
        key: "questions",
        dataIndex: "questions",
        render: (text, record) => {
          let questionCount = record.questions.length
          return (
            <div>
              {questionCount}
            </div>
          )
        }
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (text,record) => {
          if (record.results_released === true) {
            return <Tag color="green">Results released</Tag>
          } else {
            return <Tag color="red">Results Withheld</Tag>
          }
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          let urlSplit = window.location.href.split("/")
          let moduleId = urlSplit[6]
          return (
            <div>
              <Link
                to={{
                  pathname: `/courses/${urlSplit[4]}/module/${moduleId}/exercise/${record.id}`,
                }}
                onClick={() => {
                  this.props.addBreadcrumb(`/courses/${urlSplit[4]}/module/${moduleId}/exercise/${record.id}`, `${record.title}`)
                }}
              >View</Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to release the results?"
                onConfirm={() => this.releaseMarks(record)}
                disabled={record.results_released}
              >
                <Button type="link" disabled={record.results_released}>
                  Release Marks
                </Button>
              </Popconfirm>
             
            </div>
          )
        }
      }
    ]
    return (
      <>
        <div style={{ width: "100%" }}>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(exerciseData, searchText)}
          />
        </div>
      </>
    )
  }

  render() {
    let moduleDetails
    if (this.state.moduleData) {
      moduleDetails = (
        <div>
          <p style={{fontSize: 24}}>Module: {this.state.moduleData.Module.title}</p>
          <p style={{fontSize: 16}}>Description: {this.state.moduleData.Module.description}</p>
        </div>
      )
    }
    return (
      <div>
        {moduleDetails}
        <Button
          onClick={() =>{
            this.exportCSV()
          }}
          type="primary"
          style={{
            marginBottom: 20
          }}>
          Export Marks to CSV
        </Button>
        { this.TableView() }
        
      </div>
    )
  }

}

export default CourseModuleView