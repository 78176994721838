import React, { Component } from "react";

import {
  Form,
  Input,
  Button,
  Drawer,
  Col,
  Row,
  Popconfirm,
  Select,
  Upload,
  message,
  Icon,
  notification
} from "antd";
import Axios from "axios";
import ReactAudioPlayer from "react-audio-player"

const { Option } = Select;

class AssetsForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      type: this.props.form.getFieldsValue().type,
      license: this.props.form.getFieldsValue().license,
      accessibility: this.props.form.getFieldsValue().accessibility,
      file: undefined,
      userData: []
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  componentDidMount = async () => {
    const { data: userData } = await Axios.get('/auth/get_staff/')
    this.setState({ userData })
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        //this.props.form.resetFields();
        // this.props.handleOnAddAsset({ ...values }, this.props.form);
        // add the asset here instead.
        let object = values
        object.id = this.props.selectedAsset.id
        const formData = new FormData()
        if (values.upload) formData.append('file', values.upload.file)
        formData.append('asset', JSON.stringify(values))
        // in the case the upload is undefined then leave it
        const response = await Axios.put("/assets/assets/", formData)
        if (response.status === 200) this.props.updateDataSource(response.data)
        else this.openNotificationWithIcon("error", "An error occurred", response.data.message)
      } else {
        console.log(err)
      }
    });
  };

  handleSelectChange = (value, e, context) => {
    if (context === "TYPE") this.setState({ type: e.props.value })
    else if (context === "LICENSE") this.setState({ license: e.props.value })
    else this.setState({accessibility: e.props.value})
  }

  handleSelectSpecific = (value) => {
    this.setState({ specificUsers: value })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedAsset } = this.props
   
    const props = {
      name: "file",
      multiple: false,
      beforeUpload: file => {
        this.setState({file: file})
        if (this.uploadRef.state.fileList.length > 0) {
            this.uploadRef.state.fileList.shift()
        }
        return false
      },
      onChange: (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
      }
    }
    let types = [
        {
          name: "audio"
        },
        {
          name: "picture"
        }
    ]
    let ifSpecific
    let preview
    if (selectedAsset !== undefined) {
      const link = process.env.REACT_APP_ASSET_URL+"/"+selectedAsset.s3_link
      preview = (
        <Row gutter={16}>
          <Col span={24}>
            <p>Current File</p>
            <ReactAudioPlayer src={link} controls={true}/>
          </Col>
        </Row>
      )
      if (this.props.form.getFieldsValue().accessibility === "SPECIFIC") {
        ifSpecific = (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Access Users">
                {getFieldDecorator("access_users", {
                  initialValue: selectedAsset
                    ? selectedAsset.access_users
                    : "",
                  rules: [{ required: true, message: "Please select which users will have access to this asset." }]
                })(
                  <Select mode="multiple" onChange={(v) => {this.handleSelectSpecific(v)}}>
                    {this.state.userData.map((user) => (
                      <Option key={user.zid} value={user.zid}>
                          {user.zid} - {user.display_name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        )
      }
    }
    return (
      <Drawer
        title="Edit Asset"
        width={720}
        closable={false}
        onClose={this.onClose}
        visible={this.props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Title">
                {getFieldDecorator("title", {
                  initialValue: selectedAsset
                    ? selectedAsset.title
                    : "",
                  rules: [{ required: true, message: "Please enter title" }]
                })(<Input placeholder="Please enter title" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Description">
                {getFieldDecorator("description", {
                  initialValue: selectedAsset
                    ? selectedAsset.description
                    : "",
                  rules: [{ required: true, message: "Please enter question description" }]
                })(<Input placeholder="Please enter module description" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="License Type">
                {getFieldDecorator("license_type", {
                  initialValue: selectedAsset
                    ? selectedAsset.license_type
                    : "",
                  rules: [{ required: true, message: "Please select the license type" }]
                })(
                  <Select onChange={(v, e) => {this.handleSelectChange(v, e, "LICENSE")}}>
                    <Option value="ALL_RIGHTS_RESERVED">All rights reserved</Option>
                    <Option value="UNDER_THE_ACT">Under the Act</Option>
                    <Option value="CREATIVE_COMMONS">Creative Commons</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Accessibility">
                {getFieldDecorator("accessibility", {
                  initialValue: selectedAsset
                    ? selectedAsset.accessibility
                    : "",
                  rules: [{ required: true, message: "Please select the accessibility type" }]
                })(
                  <Select onChange={(v, e) => {this.handleSelectChange(v, e, "ACCESSIBILITY")}}>
                    <Option value="PRIVATE">Private</Option>
                    <Option value="PUBLIC">Public</Option>
                    <Option value="SPECIFIC">Specific</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          {ifSpecific}

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Type">
                {getFieldDecorator("type", {
                    initialValue: selectedAsset
                        ? selectedAsset.type
                        : "",
                    rules: [{ required: true, message: "Please choose the question type" }]
                })(
                    <Select onChange={(v, e) => {this.handleSelectChange(v,e, "TYPE")}}>
                        {types.map((exercise, i) => (
                            <Option key={i} value={exercise.name}>
                                {exercise.name}
                            </Option>
                        ))}
                    </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          
          {preview}

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Replace File">
                {getFieldDecorator("upload", {
                  initialValue: selectedAsset
                    ? selectedAsset.picture
                    : "",
                  rules: [{ required: false, message: "Please upload the file" }]
                })(<Upload {...props} ref={el => (this.uploadRef = el)} name="file"><Button><Icon type="upload" />Upload</Button></Upload>)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 16 }}>
            <Popconfirm
              title="Changes will be discarded."
              onConfirm={() => this.props.onCancel()}
            >
              <Button style={{ marginRight: "10px" }} type="danger">
                Cancel
              </Button>
            </Popconfirm>
            <Button type="submit" htmlType="submit" onClick={() => {}}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create({ name: "register" })(AssetsForm);

//https://api.stage.cdf.teaching.unsw.edu.au/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}

//http://localhost:5000/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}&TotalCost=${e://Field/TotalCost}
