import React, { Component } from "react";

import { Card, Button, Form, Select, Row, Col, Collapse  } from "antd";
import ReactAudioPlayer from "react-audio-player"
import axios from "axios"

const { Option } = Select;
const { Panel } = Collapse;

class RatingCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      active: true,
      clarity: 0,
      inotation: 0,
      pronunciation: 0,
      submitted: false,
      submissionTitle: "Submission " + this.props.data.submission.id
    }
  }

  componentDidMount = async () => {
  };

  submitRating = async e => {
    e.preventDefault()
    const ratingData = {
      clarity: this.state.clarity,
      inotation: this.state.inotation,
      pronunciation: this.state.pronunciation
    }
    const { data: submitData } = await axios.post("ratings/ratings/", {
      userId: sessionStorage.userId,
      submissionId: this.state.data.submission.id,
      ratingQueueId: this.state.data.id,
      rating: ratingData
    })
    this.setState({ submitted: true, submissionTitle: "Submission " + this.state.data.submission.id + " - Rating Submitted" })
  }

  handleClarityChange = (e) => {
    this.setState({ clarity: e })
  }

  handleInotationChange = (e) => {
    this.setState({ inotation: e })
  }

  handlePronunciationChange = (e) => {
    this.setState({ pronunciation: e })
  }

  render() {
    // details - module, exercise, question not user?
    // question details - question title, question_text, two examples.
    // user answer
    // three select inputs for the rating 1-5 each.
    // submit button.
    let examples = this.state.data.submission.question.assets.map((asset, i) => {
      let url = process.env.REACT_APP_ASSET_URL+"/"+asset.s3_link
      return (
        <ReactAudioPlayer src={url} controls key={i}/>
      )
    })
    const answerUrl = process.env.REACT_APP_ASSET_URL+"/"+this.state.data.submission.answer
    const submissionTitle = "Submission " + this.state.data.submission.id
    if (!this.state.submitted) {
      return (
        <div>
          <Card>
            <p>{this.state.submissionTitle}</p>
            <p>Submission by: {this.state.data.submission.user_id}</p>
            <p>Question: {this.state.data.submission.question.title}</p>
            <p>Text: {this.state.data.submission.question.question_text}</p>
            <p>Examples: </p>
            {examples}
            <p>Submission:</p>
            <ReactAudioPlayer src={answerUrl} controls />
            <div>
              <Form onSubmit={this.submitRating}>
                <Row gutter={10}>
                  <Col span={8}>
                    <Form.Item
                      label="Clarity"
                      name="clarity"
                    >
                      <Select onChange={this.handleClarityChange}>
                        <Option value="0">0</Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Intonation"
                      name="intonation"
                    >
                      <Select onChange={this.handleInotationChange}>
                        <Option value="0">0</Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Pronunciation"
                      name="pronunciation"
                    >
                      <Select onChange={this.handlePronunciationChange}>
                        <Option value="0">0</Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="submit" htmlType="submit" disabled={this.state.submitted}>
                    Submit Rating
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </div>
      )
    } else {
      return (
        <div>
          <Card style={{flex: 1, display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", alignItems: "center"}}>
            <p>Rating Submitted</p>
          </Card>
        </div>
      )
    }
    
  }
}

export default RatingCard