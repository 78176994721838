import React, { Component } from "react";

import { Modal, Button } from "antd";

import ReactToPrint from 'react-to-print'

import ModuleForm from './ModuleForm'

class SingleModuleView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      visibleModuleForm: false
    }
  }

  render() {
    let lti = "LIIIIIT"
    let title
    let description
    let maxSubmissions
    if (this.props.module !== undefined) {
      title = this.props.module.title
      description = this.props.module.description
      maxSubmissions = this.props.module.maxSubmissions
    }
    return (
      <>
        <Modal
          visible={this.props.visible}
          onOk={() => this.props.onCancel()}
          onCancel={() => this.props.onCancel()}
          style={{minWidth: "70%"}}
          footer={[
            <Button onClick={() => this.props.onCancel()}>
              OK
            </Button>
          ]}
        >
          <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding: 30}}>
            <p>
              <b>Module:</b> {title}
            </p>
            <p>
              <b>Description:</b> {description}
            </p>
            <p>
              <b>Maximum Submissions:</b> {maxSubmissions}
            </p>
            <p>
              <b>Exercises:</b> {lti}
            </p>
            <Button onClick={() => {this.setState({ visibleModuleForm: true})}}>
              Edit Module
            </Button>
          </div>
        </Modal>
        <ModuleForm
          moduleTitle="Edit module"
          onAddModule={this.handleOnAddModule} // if it actualy gets added.
          visible={this.state.visibleModuleForm}
          selectedModule={{
            title: this.props.title
          }} // in case of edit form.
          onCancel={() => this.setState({ visibleModuleForm: false })}
        />
      </>
    );
  }
}

export default SingleModuleView;
