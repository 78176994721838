import React, { Component, Suspense } from "react";
import { Route, NavLink, Switch, Redirect } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import preval from "preval.macro";
import ModulesView from './Staff/Module/ModulesView'
import AppContext from "../AppContext";
import ExercisesView from './Staff/Exercise/ExercisesView'
import QuestionsView from './Staff/Questions/QuestionsView'
import CourseDashboard from "./Staff/Courses/CourseDashboard"//ERROR HERE.
import AssetsView from "./Staff/Assets/AssetsView"//ERROR HERE
import StudentDashboard from "./Student/StudentDashboard"
import LinkModule from './Staff/Module/LinkModule'
import RateProcess from './Student/RateProcess'
import icon from '../img/p-mate_30.png'

class Dashboard extends Component {
  static contextType = AppContext;
  state = {
    fullScreenMode: false
  };

  hideSideBar = () => {
    this.setState({ fullScreenMode: true })
  }

  showSideBar = () => {
    this.setState({ fullScreenMode: false })
  }

  render() {
    const { location } = this.props;

    const { logout, isAdmin } = this.context;

    const currentKey = location.pathname.split("/")[1].toLowerCase();

    let userDetails = {}
    userDetails.name = sessionStorage.getItem("first_name")
    userDetails.id = sessionStorage.getItem("userId")
    if (isAdmin) {
      userDetails.role = "Instructor"
    } else {
      userDetails.role = "Student"
    }

    return (
      <Layout style={{ height: "100%" }}>
        <Layout.Sider collapsed={this.state.fullScreenMode} collapsedWidth={0}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              background: "#120212"
            }}
          >
            <img
              className="logo"
              src={icon}
              alt="UNSW Logo"
              style={{ width: "40px", margin: 20 }}
            />

            <div style={{ flex: 1 }}>
              {isAdmin ? (
                <Menu
                theme="dark"
                mode="inline"
                defaultOpenKeys={["manage", "application"]}
                selectedKeys={[currentKey]}
                >
                  <Menu.SubMenu title="Options" key="manage">
                    <Menu.Item key="courses">
                      <NavLink to="/courses">Courses</NavLink>
                    </Menu.Item>
                    <Menu.Item key="modules">
                      <NavLink to="/modules">Modules</NavLink>
                    </Menu.Item>
                    <Menu.Item key="exercises">
                      <NavLink to="/exercises">Exercises</NavLink>
                    </Menu.Item>
                    <Menu.Item key="questions">
                      <NavLink to="/questions">Questions</NavLink>
                    </Menu.Item>
                    <Menu.Item key="assets">
                      <NavLink to="/assets">Assets</NavLink>
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
              ) : (
                <div></div>
              )}
            </div>

            <div
              style={{
                textAlign: "center",
                color: "rgba(117, 117, 117, 0.5)",
                margin: "20px 0"
              }}
            >
              <div>
                <p style={{color: "green"}}>•</p>
                <p style={{color: "white", display: 'block'}}>{userDetails.name}</p>
                <p style={{color: "white"}}>{userDetails.id} - {userDetails.role}</p>
              </div>
              {`Build date: ${preval`
                const moment = require("moment");
                module.exports = moment().format("DD/MM/YYYY");
              `}`}
            </div>
          </div>
        </Layout.Sider>

        <Layout>
          <Layout.Header
            style={{ background: "#fff", padding: "0 2px", margin: 10 }}
          >
            <h1 style={{ marginLeft: 20, textAlign: "center" }}>
              <img src={icon} />
              PronounceMate
              
              <Button
                type="warning"
                icon="poweroff"
                onClick={logout}
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "1%"
                }}
              >
                Logout
              </Button>
              <div style={{float: "right", marginRight: "1%"}}>
                <p>Hi {userDetails.name}, {userDetails.id} - {userDetails.role}</p>
                {/* <p>{userDetails.id} - {userDetails.role}</p> */}
              </div>
              {/* <div
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "2%"
                }}
              /> */}
            </h1>
          </Layout.Header>

          <Layout.Content
            style={{
              margin: "1px 16px 0",
              display: "flex",
              flexDirection: "column",
              height: "100%"
            }}
          >
            <div style={{ padding: 24, background: "#fff", flex: "1" }}>
              <Switch>
                <Redirect exact from="/" to="/modules" />
                  {/* {isAdmin && (
                    <>
                      <Route path="/grantyear" component={GrantYear} />
                    </>
                  )} */}
                  {isAdmin ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Route exact path="/modules" component={ModulesView} />
                      <Route path="/exercises" component={ExercisesView} />
                      <Route path="/questions" component={QuestionsView} />
                      <Route path="/assets" component={AssetsView} />
                      <Route path="/courses" component={CourseDashboard} />
                      <Route path="/link_module" render={() => <LinkModule showSideBar={this.showSideBar} hideSideBar={this.hideSideBar}/>} />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Route path={`/courses/:cid/module/:id`} render={() => <StudentDashboard showSideBar={this.showSideBar} hideSideBar={this.hideSideBar} />} />
                      <Route path="/rate" component={RateProcess} />
                    </Suspense>
                  )}
                  
              </Switch>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

export default Dashboard;