import React, { Component, Stylesheet } from "react";
import { Modal, Button, Icon, Upload, Form, Input, Table, Select, Spin, Popconfirm, notification } from "antd";
import './asset.css'
import ReactAudioPlayer from "react-audio-player"
import Axios from "axios";
import AssetUsers from './AssetUsers'
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record } = this.props;
    const { editing } = this.state;

    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          // rules: [
          //   {
          //     required: true,
          //     message: `${title} is required.`
          //   }
          // ],
          initialValue: record[dataIndex]
        })(
          <Input.TextArea
            ref={node => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            autoSize={{ maxRows: 6 }}
            style={{width: 200}}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children[2] ? (
          children
        ) : (
          <div style={{ color: "#BABABA" }}>click to edit</div>
        )}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class AssetMultiple extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      specificUserList: [],
      visibleUsersModal: false,
      loading: false,
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  handleSave = row => {
    const newDataSource = [...this.state.fileList];
    const index = newDataSource.findIndex(item => row.uid === item.uid);
    const item = newDataSource[index];
    newDataSource.splice(index, 1, {
      ...item,
      ...row,
      saved: false
    });

    this.setState({ fileList: newDataSource, isNavigationBlocking: true });
  };

  updateSpecificUserList = (selectedUsers) => {
    this.setState({ specificUserList: selectedUsers }, () => console.log(this.state.specificUserList))
    this.openNotificationWithIcon("success", "Specific user list updated!")
  }

  makeTable = (fileList) => {
    const defaultColumns = [
      {
        title: "Asset Title",
        dataIndex: "title",
        editable: true,
        key: "title",
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        editable: true,
        render: text => {
          return (<p>{text}</p>)
        }
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: text => {
          return (<p>{text}</p>)
        }
      },
      {
        title: "Preview",
        key: "preview",
        dataIndex: "preview",
        render: (text, record) => {
          let url = URL.createObjectURL(record.originFileObj)
          return (
            <div>
              <ReactAudioPlayer src={url} controls/>
            </div>
          )
        }
      },
      {
        title: "Author",
        key: "author",
        dataIndex: "author",
        render: (text, record) => {
          return (
            <p>{text}</p>
          )
        }
      },
      {
        title: "Accessibility",
        key: "accessibility",
        dataIndex: "accessibility",
        render: (text, record) => {
          return (
            <Select defaultValue="PRIVATE" onChange={(e) => {this.onSelect(record, e, "ACCESSIBILITY")}}>
              <Select.Option value="PRIVATE">Private</Select.Option>
              <Select.Option value="PUBLIC">Public</Select.Option>
              <Select.Option value="SPECIFIC">Specific</Select.Option>
            </Select>
          )
        }
      },
      {
        title: "License Type",
        key: "license_type",
        dataIndex: "license_type",
        render: (text, record) => {
          return (
            <Select defaultValue="ALL_RIGHTS_RESERVED" onChange={(e) => {this.onSelect(record, e, "LICENSE")}}>
              <Select.Option value="ALL_RIGHTS_RESERVED">All Rights Reserved</Select.Option>
              <Select.Option value="UNDER_THE_ACT">Under the Act</Select.Option>
              <Select.Option value="CREATIVE_COMMONS">Creative Commons</Select.Option>
            </Select>
          )
        }
      },
      {
        title: "Action",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          return (
            <Button
              onClick={() => {this.removeItem(record)}}
            >
              <Icon type="delete" />
            </Button>
          )
        }
      }
    ]

    const columns = defaultColumns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    return (
      <Table 
        dataSource={fileList}
        columns={columns}
        components={components}
        rowClassName={() => "editable-row"}
        rowKey={(record) => record.uid}
      />
    )
  }

  removeItem = (record) => {
    let stateList = this.state.fileList
    const index = stateList.findIndex(file => file.uid === record.uid)
    stateList.splice(index, 1)
    this.setState({fileList: stateList})
  }

  changeFileObjects = (file, fileList) => {
    const me = sessionStorage.getItem('userId')
    // find the file index
    let stateList = this.state.fileList
    let fileListIndex = fileList.findIndex(f => {
      return file.uid === f.uid
    })
    if (stateList.length < fileList.length) {
      // file was added
      let indexedFile = fileList[fileListIndex]
      if (!indexedFile.title) indexedFile.title = ""
      if (!indexedFile.description) indexedFile.description = ""
      if (!indexedFile.author) indexedFile.author = me
      if (!indexedFile.license_type) indexedFile.license_type = "ALL_RIGHTS_RESERVED"
      if (!indexedFile.date_created) indexedFile.date_created = ""
      if (!indexedFile.accessibility) indexedFile.accessibility = "PRIVATE"
      if (indexedFile.type.includes("image")) indexedFile.type = "picture"
      else if (indexedFile.type.includes("audio")) indexedFile.type = "audio"
      else indexedFile.type = "unknown"

      stateList.push(indexedFile)
    } else {
      // some file was removed
      const index = stateList.findIndex(f => {
        return (file.uid === f.uid)
      })
      stateList.splice(index, 1)
    }
    return stateList
  }

  onSelect = (record, value, type) => {
    let stateList = this.state.fileList
    const index = stateList.findIndex(f => {return(record.uid === f.uid)})
    let newRecord = stateList[index]
    if (type === "ACCESSIBILITY") newRecord.accessibility = value
    else newRecord.license_type = value
    stateList[index] = newRecord
    this.setState({ fileList: stateList })
  }

  getZids = (userList) => {
    let stripped = []
    userList.forEach(user => {
      stripped.push(user.zid)
    })
    return stripped
  }

  bulkSave = async () => {
    //send all data and save.
    // receive new asset list
    // call oncancel and open notification if successful
    // make new function to replace old assetview with new datasource.
    if (this.state.fileList.length === 0) {
      this.openNotificationWithIcon("error", "Upload a file before saving!")
      return
    }

    this.setState({ loading: true })
    let stateList = this.state.fileList
    let request = []
    const formData = new FormData()
    let stripped = this.getZids(this.state.specificUserList)
    stateList.forEach(file => {
      let object = {
        title: file.title,
        description: file.description,
        author: file.author,
        type: file.type,
        license_type: file.license_type,
        accessibility: file.accessibility,
      }
      formData.append('file', file.originFileObj)
      request.push(object)
    })
    formData.append('userList', JSON.stringify(stripped))
    formData.append('metadata', JSON.stringify(request))
    const response = await Axios.post('/assets/bulkUpload/', formData)
    if (response.status === 200) {
      // sweet as bruh.
      this.setState({ loading: false })
      this.props.updateDataSource(response.data)
      this.goBack()
    }
  }

  goBack = () => {
    this.setState({fileList: []})
    this.props.goBack()
  }

  closeModal = () => {
    this.setState({visibleUsersModal: false})
  }

  render() {
    return (
      <>
        {/* <Modal
          visible={this.props.visible}
          onOk={() => this.props.onCancel()}
          style={{minWidth: "90%"}}
          closable={false}
          footer={
            (this.state.fileList.length > 0) ? (
              <Popconfirm 
                onConfirm={() => this.closeModal()}
                title="Are you sure you want to cancel?"
              >
                <Button type="danger">
                  Cancel
                </Button>
              </Popconfirm>
            ) : (
              <Button type="danger" onClick={() => this.closeModal()}>
                Cancel
              </Button>
            )
          }
        > */}
        <Button onClick={() => this.props.goBack()}>
          Back to Assets View
        </Button>
          <Spin spinning={this.state.loading} tip="Uploading...">
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding: 30}}>
              <Upload.Dragger showUploadList={false} fileList={this.state.fileList} multiple ref={el => (this.uploadRef = el)} name="file"
                beforeUpload={() => {return(false)}}
                onChange={(e) => {this.setState({fileList: this.changeFileObjects(e.file, e.fileList)})}}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                  band files
                </p>
              </Upload.Dragger>
              {/* {(this.state.fileList.length > 0) ? (<div style={{paddingTop: 10, paddingBottom: 10}}>
                <Button>
                  Add users to files with "Specific" accessibility.
                </Button>
              </div>) : (null)} */}
              {this.makeTable(this.state.fileList)}
              <Button onClick={() => this.setState({ visibleUsersModal: true })}>
                Add users to files with "Specific" accessibility.
              </Button>
              <Button
                type="primary"
                onClick={() => this.bulkSave()}>
                  Save Assets
              </Button>
            </div>
          </Spin>
        {/* </Modal> */}
        <AssetUsers 
          visible={this.state.visibleUsersModal} 
          closeModal={() => {this.closeModal()}}
          updateSpecificUserList={this.updateSpecificUserList}
        />
      </>
    );
  }
}

export default AssetMultiple
