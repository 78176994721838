import React, { Component } from "react";

import {
  Form,
  Input,
  Button,
  Drawer,
  Col,
  Row,
  Popconfirm,
  Select,
  Tooltip,
  Radio,
  Icon,
} from "antd";

import axios from "axios"

const { TextArea } = Input

const { Option } = Select;

let id = 0

class QuestionForm extends Component {
  state = {};

  constructor(props) {
    super(props)
    this.state = {
      type: this.props.form.getFieldsValue().type,
      assets: this.props.form.getFieldsValue().assets,
      selectedAssets: [],
      choiceForm: [],
      choiceNumbers: 0,
    }
  }

  componentDidMount = async () => {
    const assetData = await axios.get("assets/get_assets")
    this.setState({ assets: assetData.data })
  }

  componentDidUpdate = (prevProps, previousState) => {
    if (prevProps.selectedQuestion !== this.props.selectedQuestion && this.props.selectedQuestion) {
      this.setState({selectedAssets: this.props.selectedQuestion.assets})
      if (this.props.selectedQuestion.type === "text") {
        let keys = [], choices = []
        Object.keys(this.props.selectedQuestion.question_json).forEach(key => {
          keys.push(key)
          choices.push(this.props.selectedQuestion.question_json[key])
        })
        // keys = 
        this.props.form.setFieldsValue({
          keys: keys,
          choices: choices
        })
      }
    }
    // if (this.props.selectedQuestion) this.setState({ selectedAssets: this.props.selectedQuestion.assets})
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //this.props.form.resetFields();
        values = this.props.form.getFieldsValue()
        let object = values
        if (values.type === "text") {
          let question_json = {}
          let number = 1
          let answers = {}
          // we have answer
          // get index of keys of the answer
          let indexKeys = values.keys.findIndex(key => key == values.answer)
          object.answer = indexKeys+1
          values.choices.forEach(choice => {
            question_json[number] = choice
            number++
          })
          object.question_json = question_json
          delete object["keys"]
          delete object["choices"]
        } else if (values.type === "pronunciation-hanzi" || values.type === "hanzi-learning" || values.type === "hanzi-quiz") {
          values["question_json"] = {
            hanzi: values.hanzi
          }
          delete values["hanzi"]
        }

        this.props.handleOnAddQuestion({ ...object }, this.props.form);
      }
    });
  };

  assetsToIds = (assets) => {
    let ids = []
    assets.forEach(asset => {
      ids.push(asset.id)
    })
    return ids
  }

  handleSelectChange = (value, e) => {
    this.setState({ type: e.props.value })
  }

  handleAssetsSelect = async (value, options) => {
    // if not already in the array, add it
    let selected = this.parseOptions(options)
    this.setState({ selectedAssets: selected })
  }

  parseOptions = (options) => {
    let array = []
    options.forEach(option => {
      array.push({
        id: option.props.value,
        title: option.props.children
      })
    })
    return array
  }

  parseAssets = (assets) => {
    let array = []
    assets.forEach(asset => {
      array.push(asset.id)
    })
    return array
  }

  randomiseRadio = (e) => {
    this.setState({ randomise: e })
  }

  getAnswerDetailsMCQ = (selectedAssets, id) => {
    let answer = selectedAssets.findIndex(
      record => record.id === parseInt(id)
    )
    return selectedAssets[answer]
  }

  getHanzi = (json) => {
    if (json) return json.hanzi
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedQuestion } = this.props
    getFieldDecorator('keys', { initialValue: []});
    const keys = this.props.form.getFieldsValue().keys
    let types = [
      {
        name: "text",
      },
      {
        name: "mcq"
      },
      {
        name: "pronunciation"
      },
      { name: "pronunciation-hanzi" },
      { name: "hanzi-learning" },
      { name: "hanzi-quiz" }
    ]
    let answerForm
    let pronunciationForm
    let mcqForm, textForm, randomiseForm, pronunciationHanziForm
    let type
    let choices = []
    if (selectedQuestion !== undefined) {
      type = this.props.form.getFieldsValue().type
      console.log(this.props.form.getFieldsValue())
      // console.log(type)
    } else {
      type = this.state.type
    }

    if (type === "text") {
      answerForm = (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Correct Choice">
              {getFieldDecorator("answer", {
                initialValue: selectedQuestion
                  ? selectedQuestion.answer
                  : "",
                rules: [{ required: false, message: "Please enter the answer" }]
              })(
                <Select>
                  {keys.map(choice => {
                    return (
                      <Option key={choice} value={choice}>
                        {choice}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      )
    }

    if (type === "pronunciation" || type === "pronunciation-hanzi" || type === "hanzi-learning" || type === "hanzi-quiz") {
      pronunciationForm = (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Audio files">
                {getFieldDecorator("assets", {
                  initialValue: selectedQuestion
                    ? this.assetsToIds(selectedQuestion.assets)
                    : [],
                  rules: [{ required: false, message: "Please choose an audio asset" }]
                })(
                  <Select mode="multiple">
                    {this.state.assets.map((asset, i) => {
                      if (asset.type === "audio") {
                        return (
                          <Option key={asset.id} value={asset.id}>
                            {asset.title}
                          </Option>
                        )
                      }
                      return false
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    }

    if (type === "pronunciation-hanzi" || type === "hanzi-learning" || type === "hanzi-quiz") {
      pronunciationHanziForm = (
        <Row gutter={16}>
          <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Hanzi Character(s)</p>
                <Tooltip title="The Hanzi character(s) drawing pattern will be shown and animated.">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
          </Col>
          <Col span={18}>
            <Form.Item>
              {getFieldDecorator("hanzi", {
                initialValue: selectedQuestion
                  ? this.getHanzi(selectedQuestion.question_json)
                  : "",
                  rules: [{ required: true, message: "Please enter in at least one Hanzi character. "}]
              })(
                <Input placeholder="Hanzi Character" />
              )}
            </Form.Item>
          </Col>
        </Row>
      )
    }

    if (type === "mcq") {
      mcqForm = (
        <>
          <Row gutter={16}>
            <Col span={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                  <p style={{ paddingRight: 10 }}>Assets</p>
                  <Tooltip title="Select the options which the student will choose the answer from. Can be a mix of pictures and audio">
                    <Button type="link">?</Button>
                  </Tooltip>
                </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("assets", {
                  initialValue: selectedQuestion
                    ? this.parseAssets(selectedQuestion.assets)
                    : [],
                  rules: [{ required: false, message: "Please choose assets" }]
                })(
                  <Select mode="multiple" onChange={(value, option) => this.handleAssetsSelect(value, option)}>
                    {this.state.assets.map((asset, i) => {
                      return (
                        // <Option key={asset.id} value={`{ id: ${asset.id}, title: ${asset.title}`}>
                        <Option key={asset.id} value={asset.id}>
                          {asset.title}
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )
      answerForm = (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Question Answer">
              {getFieldDecorator("answer", {
                initialValue: selectedQuestion
                  ? parseInt(selectedQuestion.answer)
                  : '',
                rules: [{ required: false, message: "Please enter the answer" }]
              })(
                <Select>
                  {this.state.selectedAssets.map((asset, i) => {
                    return (
                      <Option key={asset.id} value={asset.id}>
                        {asset.title}
                      </Option>
                    )
                  })}
                </Select>)}
            </Form.Item>
          </Col>
        </Row>
      )

      randomiseForm = (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Randomise MCQ Answers">
              {getFieldDecorator("randomise", {
                initialValue: selectedQuestion
                  ? selectedQuestion.randomise
                  : false,
              })(
                <Radio.Group onChange={this.randomiseRadio} defaultValue={true}>
                  <Radio.Button value={true}>True</Radio.Button>
                  <Radio.Button value={false}>False</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      )
    }

    if (type === "text") {
      let choiceForm = keys.map((choice, i) => {
        let label = "Choice " + choice
        let decorator = "choice_" + choice
        return (
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item label={label}>
                {getFieldDecorator(`choices[${choice}]`, {
                  initialValue: selectedQuestion
                    ? selectedQuestion.question_json[choice]
                    : '',
                  rules: [{ required: true, message: "Please enter choice" }]
                })(
                  <Input placeholder="Please enter a possible answer" />
                 
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                style={{marginTop: 30}}
                type="danger"
                onClick={() => {
                  // remove current from map.
                  const { form } = this.props
                  let keys = form.getFieldValue('keys')
                  if (keys.length === 1) {
                    return
                  }
                  const index = keys.findIndex(key => key === choice)
                  keys.splice(index, 1)
                  form.setFieldsValue({
                    keys: keys
                  })
                }}
              ><Icon type="minus-circle"/></Button>
            </Col>
          </Row>
        )
      })

      textForm = (
        <div>
          {choiceForm}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Button 
                  style={{width: "100%"}}
                  type="primary"
                  onClick={() => {
                    const  { form } = this.props
                    const keys = form.getFieldValue('keys')
                    let nextKeys
                    if (keys.length === 0) nextKeys = [1]
                    else nextKeys = keys.concat(parseInt(keys[keys.length-1])+1)
                    form.setFieldsValue({ keys: nextKeys })
                  }}>
                  <Icon type="plus-circle" />
                  Add another choice
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <Drawer
        title="Create/Edit Question"
        width={720}
        closable={false}
        onClose={this.onClose}
        visible={this.props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Title</p>
                <Tooltip title="The title of the question">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("title", {
                  initialValue: selectedQuestion
                    ? selectedQuestion.title
                    : "",
                  rules: [{ required: true, message: "Please enter title" }]
                })(<Input placeholder="Question Title" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Description</p>
                <Tooltip title="Provide a short description of the question">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("description", {
                  initialValue: selectedQuestion
                    ? selectedQuestion.description
                    : "",
                  rules: [{ required: true, message: "Please enter question description" }]
                })(<TextArea placeholder="Question Description" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Question Text</p>
                <Tooltip title="The question text itself, what the question requires the student to do">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("question_text", {
                  initialValue: selectedQuestion
                    ? selectedQuestion.question_text
                    : "",
                  rules: [{ required: true, message: "Please enter the question text" }]
                })(<TextArea placeholder="Question Text" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6} style={{ display: "flex"}}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex"}}>
                <p style={{ paddingRight: 10 }}>Question Type</p>
                <Tooltip title="What kind of question is it, multiple choice or pronunciation?">
                  <Button type="link">?</Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("type", {
                  initialValue: selectedQuestion
                    ? selectedQuestion.type
                    : [],
                  rules: [{ required: true, message: "Please choose the question type" }]
                })(
                  <Select onChange={(v, e) => { this.handleSelectChange(v, e) }}>
                    {types.map((exercise, i) => (
                      <Option key={i} value={exercise.name}>
                        {exercise.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          {pronunciationHanziForm}

          {pronunciationForm}

          {mcqForm}

          {textForm}

          {answerForm}

          {randomiseForm}

          <Form.Item wrapperCol={{ offset: 16 }}>
            <Popconfirm
              title="Changes will be discarded."
              onConfirm={() => this.props.onCancel()}
            >
              <Button style={{ marginRight: "10px" }} type="danger">
                Cancel
              </Button>
            </Popconfirm>
            <Button type="submit" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create({ name: "register" })(QuestionForm);

//https://api.stage.cdf.teaching.unsw.edu.au/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}

//http://localhost:5000/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}&TotalCost=${e://Field/TotalCost}
