import React from 'react'
import { Button } from 'antd'
import HanziWriter from 'hanzi-writer'
import axios from "axios"

class HanziQuiz extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      delayBetweenStrokes: 800,
      delayBetweenLoops: 2000,
      attempt: false,
      character: this.props.selectedQuestion.question_json.hanzi,
      mistakes: 0,
      completed: false,
    }
    this.attemptRef = React.createRef()
  }

  makeExampleHanzi = () => {
    const example = HanziWriter.create('hanzi-example', this.state.character, {
      width: window.innerHeight*0.1,
      height: window.innerHeight*0.1,
      padding: 5,
    })
  }

  makeAttemptHanzi = () => {
    const writer = HanziWriter.create('hanzi-attempt', this.state.character, {
      width: window.innerHeight*0.4,
      height: window.innerHeight*0.4,
      padding: 5,
      showCharacter: false,
      showHintAfterMisses: 100,
    })
    this.attemptRef = writer
    writer.quiz({
      onMistake: (strokeData) => {
        this.setState({ mistakes: strokeData.totalMistakes })
      },
      onCorrectStroke: (strokeData) => {
        console.log(strokeData)
      },
      onComplete: () => {
        console.log(this.state.mistakes)
        this.setState({ completed: true })
      }
    })
  }

  handleSubmitHanzi = async () => {
    const formData = new FormData()
    let userId = sessionStorage.userId
    let urlSplit = window.location.href.split('/')
    let course = urlSplit[4]
    let module = urlSplit[6]
    let exercise = urlSplit[8]
    let type = this.props.selectedQuestion.type
    formData.append('meta', JSON.stringify({
      courseId: course,
      userId: userId,
      moduleId: module,
      exerciseId: exercise,
      questionId: this.props.selectedQuestion.id,
      type: type, 
      answer: this.state.mistakes
    }))
    const response = await axios.post("submissions/submissions/", formData)
    if (response.status === 200) {
      this.props.questionSaved(response.data)
    }
  }

  componentDidMount = () => {
    this.makeAttemptHanzi()
    this.makeExampleHanzi()
  }

  render = () => {
    const selectedQuestion = this.props.selectedQuestion

    // if it's learning, then watch the animation with speed up / down.
    return (
      <div style={{flex: 1, display: "flex", flexDirection: "column" , justifyContent: "center", textAlign: "center", alignItems: "center"}}>
        <div id="hanzi-example"></div>
        <div id="hanzi-instructions">
          <p>Please attempt to draw the above character. Remember, order of lines and direction is important!</p>
        </div>
        <div id="hanzi-attempt" style={{border: "1px solid", width: window.innerHeight*0.4, height: window.innerHeight*0.4}}>
        </div>
        <div>
          <p> Mistakes made: {this.state.mistakes} </p>
        </div>
        { this.props.submit ? ( <Button disabled={!this.state.completed} onClick={() => this.handleSubmitHanzi()}>
          Submit
        </Button>) : (null)}
      </div>
    )
  }
}

export default HanziQuiz