import React, { Component } from "react";

import { Modal, Button, Table } from "antd";

import ReactAudioPlayer from "react-audio-player"

class ExercisePreview extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedExercise: undefined
    }
  }

  questionTable = (dataSource) => {
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        editable: false,
        key: "title",
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: text => {
          return (
            <p>{text}</p>
          );
        }
      },
      {
        title: "Question Text",
        key: "question_text",
        dataIndex: "question_text",
        render: (text, record) => {
          return (
            <div>
              <p>{text}</p>
            </div>
          )
        }
      },
      {
        title: "Preview",
        key: "preview",
        dataIndex: "preview",
        render: (text, record) => {
          let assets
          assets = record.assets.map(asset => {
            let url = process.env.REACT_APP_ASSET_URL+"/"+asset.s3_link
            if (asset.type === "audio") {
              return (
                <ReactAudioPlayer
                  key={asset.s3_link}
                  src={url}
                  controls
                  style={{paddingLeft: "10"}}
                />
              )
            } else if (asset.type === "picture") {
              return (
                <img style={{paddingLeft: 10}} src={url} height="60" key={asset.s3_link}/>
              )
            }
          })
          return (
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
              {assets}
            </div>
          )
        }
      },
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40 }}>
            Questions/Pronunciations
          </span>

          <Table
            rowKey={record => record.id}
            bordered
            columns={columns}
            pagination={{ position: 'both', defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            dataSource={dataSource}
          />

        </div>
      </>
    )
  }

  render() {
    let exerciseDetails
    let { selectedExercise } = this.props
    if (selectedExercise) {
      let pronunciationDetails
      if (selectedExercise.type === "pronunciation") {
        pronunciationDetails = (
          <>
            <p>Minimum reviews: {selectedExercise.min_reviews}</p>
            <p>Maximum review per student: {selectedExercise.max_reviews_per_student}</p>
            <p>Minmum reviews successful: {selectedExercise.min_reviews_success}</p>
          </>
        )
      }
      exerciseDetails = (
        <div>
          <p>Title: {selectedExercise.title}</p>
          <p>Description: {selectedExercise.description}</p>
          <p>Type: {selectedExercise.type}</p>
          <p>Results released: {selectedExercise.results_released}</p>
          {pronunciationDetails}
          {this.questionTable(selectedExercise.questions)}
        </div>
      )
    }

    return (
      <>
        <Modal
          visible={this.props.visible}
          onOk={() => this.props.onCancel()}
          onCancel={() => this.props.onCancel()}
          style={{minWidth: "70%"}}
          footer={[
            <Button onClick={() => this.props.onCancel()}>
              OK
            </Button>
          ]}
        >
          <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding: 30}}>
            {exerciseDetails}
          </div>
        </Modal>
      </>
    );
  }
}

export default ExercisePreview
