import React, { Component } from "react";

import axios from "axios";
import queryString from "query-string"
import RatingCard from './RatingCard.js'
import { Modal, Button } from "antd";

class RatingProcess extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      ratingQueueData: [],
    }
  }

  componentDidMount = async () => {
    const { courseId, moduleId, exerciseId } = this.props
    // get rating based on course/module/exercise
    const userId = sessionStorage.getItem('userId')
    const { data: ratingQueueData } = await axios.get(
      `ratings/get_rating_queue_object/?courseId=${courseId}&moduleId=${moduleId}&exerciseId=${exerciseId}&userId=${userId}`
    )
    this.setState({ ratingQueueData })
  };

  render() {
    let ratingCards = this.state.ratingQueueData.map((card, i) => {
      return (
        <RatingCard data={card} key={i}/>
      )
    })
      // details - module, exercise, question not user?
      // question details - question title, question_text, two examples.
      // user answer
      // three select inputs for the rating 1-5 each.
      // submit button.
    return (
      <Modal
        visible={this.props.visible}
        onOk={() => this.props.onCancel()}
        onCancel={() => this.props.onCancel()}
        style={{minWidth: "50%"}}
        footer={[
          <Button onClick={() => this.props.onCancel()} key={1}>
            OK
          </Button>
        ]}
      >
        { this.state.ratingQueueData.length === 0 ? (
          <div>
            <p>Either you have completed your ratings, or there are none available at this time.</p>
          </div>
        ) : (
          <div>
            {ratingCards}
          </div>
        )}
      </Modal>
    )
  }
}

export default RatingProcess