import React, { Component } from "react";

import { Table, Input, Icon, Tag, Button, Divider, notification, Tabs, Breadcrumb  } from "antd";
import { Route, Switch, Link } from "react-router-dom"
import Highlighter from "react-highlight-words";
import axios from "axios";
import Exercise from './Exercise.js'
const { TabPane } = Tabs

class RatingTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      searchText: "",
      ratingsData: [],
      exercise: this.props.selectedExercise
    }
  }

  

  componentDidMount = async () => {
    let userId = sessionStorage.getItem("userId")
    let urlSplit = window.location.href.split("/")

    const { data: ratingsData } = await axios.get(
      `ratings/get_completed_ratings/?userId=${userId}&moduleId=${urlSplit[6]}&courseId=${urlSplit[4]}&exerciseId=${urlSplit[8]}`
    )
    this.setState({ fetching: false, ratingsData })
  };

  TableView = () => {
    const { ratingsData, fetching } = this.state;
    const columns = [
      {
        title: "Created At",
        dataIndex: "created_at",
        editable: false,
        key: "created_at",
        render: text => {
          return (
            <p>{text}</p>
          )
        }
      },
      {
        title: "Submission By",
        dataIndex: "submission_by",
        key: "submission_by",
        render: (text,record) => {
          return (
            <p>{record.submission.user_id}</p>
          )
        }
      },
      {
        title: "Question",
        key: "question",
        dataIndex: "question",
        render: (text, record) => {
          return (
            <div>
              {record.submission.question.title}
            </div>
          )
        }
      },
      {
        title: "Rating Given",
        key: "rating",
        dataIndex: "rating",
        render: (text, record) => {
          let rating = JSON.parse(record.rating)
          return (
            <div>
              <p>Clarity: {rating.clarity}/5</p>
              <p>Inotation: {rating.inotation}/5</p>
              <p>Pronunciation: {rating.pronunciation}/5</p>
            </div>
          )
        }
      },
    ]
    return (
      <>
        <div style={{ width: "100%" }}>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={ratingsData}
          />
        </div>
      </>
    )
  }

  render() {
    return (
      <div>
        <p>
          You have completed {this.state.ratingsData.length}/{this.state.exercise.max_reviews_per_student} ratings for this exercise.
        </p>
        { this.TableView() }
      </div>
    )
  }
}

export default RatingTable