import React, { Component } from "react";

import { Modal, Button, Form, Row, Col, Select } from "antd";
import ReactAudioPlayer from "react-audio-player"
import axios from "axios";
import queryString from "query-string"

const { Option } = Select

class AdminRate extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      clarity: 0,
      inotation: 0,
      pronunciation: 0,
    }
  }

  componentDidMount = async () => {
  };

  handleClarityChange = (e) => {
    this.setState({ clarity: e })
  }

  handleInotationChange = (e) => {
    this.setState({ inotation: e })
  }

  handlePronunciationChange = (e) => {
    this.setState({ pronunciation: e })
  }

  submitRating = async e => {
    e.preventDefault()
    const ratingData = {
      clarity: this.state.clarity,
      inotation: this.state.inotation,
      pronunciation: this.state.pronunciation
    }
    const submitData = await axios.post("ratings/admin_rating/", {
      userId: sessionStorage.userId,
      submissionId: this.props.selectedSubmission.id,
      rating: ratingData
    })
    if (submitData.status === 200) {
      this.setState({ submitted: true })
      this.props.addAdminRating(submitData.data)
    } else {
      this.setState({ submitted: false })
    }
   
  }

  generateForm = () => {
    return (
      <div>
        <Form onSubmit={this.submitRating}>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                label="Clarity"
                name="clarity"
              >
                <Select onChange={this.handleClarityChange}>
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Intonation"
                name="intonation"
              >
                <Select onChange={this.handleInotationChange}>
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Pronunciation"
                name="pronunciation"
              >
                <Select onChange={this.handlePronunciationChange}>
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="submit" htmlType="submit" disabled={this.state.submitted}>
              Submit Rating
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  render() {
    let studentname, zid
    // need the question details, their submission, and then the rating card.
    
    let userDetails, questionDetails
    if (this.props.selectedSubmission !== undefined && this.props.selectedQuestion !== undefined) {
      userDetails = (
        <div>
          <p>User: {this.props.selectedSubmission.user_id}</p>
        </div>
      )
      let examples = this.props.selectedQuestion.assets.map((asset, i) => {
        let url = process.env.REACT_APP_ASSET_URL+"/"+asset.s3_link
        return (
          <ReactAudioPlayer src={url} key={i} controls />
        )
      })
      let submissionURL = process.env.REACT_APP_ASSET_URL+"/"+this.props.selectedSubmission.answer
      questionDetails = (
        <div>
          <p>Question: {this.props.selectedQuestion.title}</p>
          <p>Question Text: {this.props.selectedQuestion.question_text}</p>
          <p>Description: {this.props.selectedQuestion.description}</p>
          <p>Examples: </p>
          {examples}
          <p>Submission: </p>
          <ReactAudioPlayer src={submissionURL} controls />
        </div>
      )
    }

    return (
      <Modal
        visible={this.props.visible}
        onOk={() => this.props.onCancel()}
        onCancel={() => this.props.onCancel()}
        style={{minWidth: "70%"}}
        footer={[
          <Button key={1} onClick={() => this.props.onCancel()}>
            OK
          </Button>
        ]}
      >
        <div>
          <p style={{fontSize: 20}}>Add Rating</p>
          {userDetails}
          {questionDetails}
          {this.generateForm()}
        </div>
      </Modal>
    )
  }
}

export default AdminRate