import React, { Component } from "react";

import {
  Form,
  Input,
  Button,
  Drawer,
  Col,
  Row,
  Popconfirm,
  Select,
  Radio,
  Tooltip
} from "antd";


const { Option } = Select;

class ModuleForm extends Component {
  state = {};

//   constructor(props) {
//     super(props)
//     this.state = {
//     }
//   }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //this.props.form.resetField  s();
        if (values.exercises.includes(0)) {
          values.exercises = []
        }
        this.props.handleOnAddModule({ ...values }, this.props.form);
      }
    });
  };

  parseInitialExercises = (exercises) => {
    let exercisesArray = []
    exercises.forEach(ex => {
      exercisesArray.push(ex.id)
    })
    return exercisesArray
  }

  radioChange = (e) => {
    this.setState({ radio: e.target.value })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedModule } = this.props
    let exerciseForm
    if (this.props.exercises !== undefined) {
      let exercises = this.props.exercises
      exercises = [{id: 0, title: "None selected - you can create exercises in the next step"}].concat(exercises)
      exerciseForm = (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Exercises">
              {getFieldDecorator("exercises", {
                  initialValue: selectedModule
                      ? this.parseInitialExercises(selectedModule.exercises)
                      : [],
                  rules: [{ required: false, message: "Please choose some exercises" }]
              })(
                  <Select mode="multiple">
                      {exercises.map((exercise, i) => (
                          <Option key={i} value={exercise.id}>
                              {exercise.title}
                          </Option>
                      ))}
                  </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      )
    }
    return (
      <Drawer
        title={this.props.moduleTitle}
        width={720}
        closable={false}
        onClose={this.onClose}
        visible={this.props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
      >
        <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={4}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex", paddingTop: 5}}>
                <p>Title</p>
              </div>
            </Col>
            <Col span={2}>
              <div style={{paddingTop: 5}}>
                <Tooltip title="Title of the module"> 
                  <a>?</a>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("title", {
                  initialValue: selectedModule
                    ? selectedModule.title
                    : "",
                  rules: [{ required: true, message: "Please enter title" }]
                })(<Input placeholder="Please enter title" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={4}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex", paddingTop: 5}}>
                <p>Description</p>
              </div>
            </Col>
            <Col span={2}>
              <div style={{paddingTop: 5}}>
                <Tooltip title="Description of the module"> 
                  <a>?</a>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("description", {
                  initialValue: selectedModule
                    ? selectedModule.description
                    : "",
                  rules: [{ required: true, message: "Please enter module description" }]
                })(<Input placeholder="Please enter module description" />)}
              </Form.Item>
            </Col>
          </Row>

          {exerciseForm}

          <Row gutter={16}>
            <Col span={4}>
              <div style={{ flexDirection: "row", flex: 1, display: "flex", paddingTop: 5}}>
                <p>Map To Exercise</p>
              </div>
            </Col>
            <Col span={2}>
              <div style={{paddingTop: 5}}>
                <Tooltip title="Allows the student to be redirected to the first exercise of the module from the moodle link."> 
                  <a>?</a>
                </Tooltip>
              </div>
            </Col>
            <Col span={18}>
              <Form.Item>
                {getFieldDecorator("map_to_exercise", {
                  initialValue: selectedModule
                    ? selectedModule.map_to_exercise
                    : false,
                })(<Radio.Group onChange={this.radioChange}>
                    <Radio.Button value={true}>True</Radio.Button>
                    <Radio.Button value={false}>False</Radio.Button>
                  </Radio.Group>)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 16 }}>
            <Popconfirm
              title="Changes will be discarded."
              onConfirm={() => this.props.onCancel()}
            >
              <Button style={{ marginRight: "10px" }} type="danger">
                Cancel
              </Button>
            </Popconfirm>
            <Button type="submit" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create({ name: "register" })(ModuleForm);

//https://api.stage.cdf.teaching.unsw.edu.au/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}

//http://localhost:5000/response/return-grade?SID=${e://Field/SID}&Email=${e://Field/Email}&ResponseID=${e://Field/ResponseID}&AppID=${e://Field/AppID}&TotalCost=${e://Field/TotalCost}
