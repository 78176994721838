import React, { Component } from "react";

import { Table, Input, Icon, Popconfirm, Button, Divider, notification, Radio } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import ReactAudioPlayer from "react-audio-player"

import QuestionForm from './QuestionForm'
import QuestionDuplicate from "./QuestionDuplicate";
import QuestionPreview from "./QuestionPreview";

class QuestionsView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true, 
      dataSource: [], 
      visibleQuestionForm: false,
      visibleQuestionPreview: false,
      currentQuestion: undefined,
      assets: undefined,
      filter: "no_filter",
    }
  }

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("questions/get_questions/");
    this.setState({ dataSource, fetching: false });
  };

  searchEntry(dataSource, searchText) {
    if (!searchText && this.state.filter === "no_filter") return dataSource;
    else if (!searchText && this.state.filter !== "no_filter") {
      console.log("yeet2")
      return dataSource.filter(
        it => (it.type.toLowerCase() === this.state.filter)
      )
    }
    let searchFilter = dataSource.filter(
      it =>
        !it.title || (it.title && it.title.toLowerCase().includes(searchText))
        || (it.description && it.description.toLowerCase().includes(searchText))
    );
    if (this.state.filter !== "no_filter") {
      return searchFilter.filter(
        it => !it.type || (it.type && it.type.toLowerCase().includes(this.state.filter))
      )
    }
    return searchFilter
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  handleOnAddQuestion = async (course, props) => {
    const { currentQuestion } = this.state;
    const newQuestion = { ...currentQuestion, ...course };
    this.setState({ fetching: true });

    // need to first convert files into formdata?

    try {
      const { data } = currentQuestion
        ? await axios.put("questions/questions/", newQuestion)
        : await axios.post("questions/questions/", newQuestion);
      const index = this.state.dataSource.findIndex(
        record => record.id === data.id
      );
      props.resetFields();
      if (currentQuestion) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [ data, ...this.state.dataSource ]
          : [
              ...this.state.dataSource.slice(0, index),
              data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        visibleQuestionForm: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  handleOnDeleteQuestion = async (id) => {
    this.setState({ fetching: true });
    try {
      const deleteRequest = await axios.delete("questions/questions", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        record => record.id !== id
      );
      if (deleteRequest.status === 204) {
        this.openNotificationWithIcon("success", "Record deleted successfully");
      }
      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  }

  filterRadio = (e) => {
    this.setState({ filter: e.target.value })
  }

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: text => {
          return (
            <p>{text}</p>
          );
        }
      },
      {
        title: "Question Text",
        key: "question_text",
        dataIndex: "question_text",
        render: (text, record) => {
          return (
            <div>
              <p>{text}</p>
            </div>
          )
        }
      },
      {
        title: "Preview",
        key: "preview",
        dataIndex: "preview",
        render: (text, record) => {
          let assets
          assets = record.assets.map(asset => {
            let url = process.env.REACT_APP_ASSET_URL+"/"+asset.s3_link
            if (asset.type === "audio") {
              return (
                <ReactAudioPlayer
                  key={asset.s3_link}
                  src={url}
                  controls
                  style={{paddingLeft: "10"}}
                />
              )
            } else if (asset.type === "picture") {
              return (
                <img style={{paddingLeft: 10}} src={url} height="60" key={asset.s3_link}/>
              )
            }
          })
          return (
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
              {assets}
            </div>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          return (

            <div>
              <Button type="link" onClick={() => {this.setState({ visibleQuestionPreview: true, currentQuestion: record})}}>
                View
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={() => {this.setState({ visibleQuestionForm: true, currentQuestion: record})}}>
                Edit
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={() => {this.setState({ visibleQuestionDuplicate: true, currentQuestion: record})}}>
                Duplicate
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to delete the question? This will the question from all exercises."
                onConfirm={() => {this.handleOnDeleteQuestion(record.id)}}
              >
                <Button type="link">
                  Delete
                </Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40 }}>
            Questions/Pronunciations
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Description"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <Button
              style={{ marginLeft: "10px", float: "right" }}
              type="primary"
              onClick={() => {
                this.setState({ visibleQuestionForm: true, currentQuestion: undefined })
              }}
            >
              Create New
            </Button>
          </div>

          <div style={{width: "70%", display: "flex", flex: 1, paddingBottom: 20, flexDirection: "column"}}>
            <p style={{paddingRight: 30}}>Filter by Type</p>
            <Radio.Group onChange={this.filterRadio} defaultValue="no_filter">
              <Radio.Button value="no_filter">No filter</Radio.Button>
              <Radio.Button value="pronunciation">Pronunciations</Radio.Button>
              <Radio.Button value="mcq">MCQ</Radio.Button>
            </Radio.Group>
          </div>

          <QuestionForm
            visible={this.state.visibleQuestionForm}
            selectedQuestion={this.state.currentQuestion}
            onCancel={() => {this.setState({ visibleQuestionForm: false })}}
            handleOnAddQuestion={this.handleOnAddQuestion}
            assets={this.state.assets}
          />

          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            pagination={{ position: 'both', defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            dataSource={this.searchEntry(dataSource, searchText)}
          />

        </div>
      </>
    )
  }

  handleOnCancel() {
    this.setState({ visibleCreateQuestion: false })
  }

  duplicateQuestion = async (question, title, description) => {
    const { data: response } = await axios.post("questions/duplicate_question/", {
      id: question.id,
      title: title,
      description: description
    });
    // now add to the current state.
    // then close the modal
    let dataSource = [ response, ...this.state.dataSource ]
    this.setState({ dataSource,  visibleQuestionDuplicate: false })
  }

  render() {
    return (
      <div>
        {this.TableView()}
        <QuestionDuplicate
          visible={this.state.visibleQuestionDuplicate}
          selectedQuestion={this.state.currentQuestion}
          duplicateQuestion={this.duplicateQuestion}
          onCancel={() => {this.setState({currentQuestion: undefined, visibleQuestionDuplicate: false})}}
        />
        <QuestionPreview 
          selectedQuestion={this.state.currentQuestion} 
          visible={this.state.visibleQuestionPreview}
          onCancel={() => {this.setState({currentQuestion: undefined, visibleQuestionPreview: false})}}
        />
      </div>
    )
  }
}

export default QuestionsView