import React, { Component } from "react";

import { Breadcrumb  } from "antd";
import { Route, Switch, Link } from "react-router-dom"
import axios from "axios";
import ExerciseTable from './ExerciseTable'
import Exercise from './Exercise'

class StudentDashboard extends Component {

  constructor(props) {
    super(props)
    let module = undefined
    // if (this.props.location.state.selectedModule !== undefined) {
    //   module = this.props.location.state.selectedModule
    // }
    this.state = {
      fetching: true,
      searchText: "",
      selectedModule: module,
      exerciseTable: true,
      crumbs: []
    }
  }

  componentDidMount = async () => {
    // let id = this.props.location.state.selectedModule.id
    let urlSplit = window.location.href.split('/')
    // 4th is the module number
    let moduleId = urlSplit[6]
    const { data: dataSource } = await axios.get("modules/modules/?id=" + moduleId)
    let crumbs = []
    if (urlSplit.length > 6) {
      crumbs.push({link: `/courses/${urlSplit[4]}/module/` + moduleId, title: dataSource.Module.title})
    }
    if (urlSplit.length > 8) {
      crumbs.push({link: `/courses/${urlSplit[4]}/module/${moduleId}/exercise/${urlSplit[8]}`, title: `Exercise - ${urlSplit[6]}`})
    }
    if (this.props.hideSideBar !== undefined) {
      this.props.hideSideBar()
    }
    this.setState({ selectedModule: dataSource.Module, fetching: false, crumbs})
  };

  addBreadcrumb = (link, title) => {
    let current = this.state.crumbs
    current.push({link, title})
    this.setState({ crumbs: current })
  }

  // remove all breadcrumbs infront of the supplied index
  removeBreadcrumb = (pos) => {
    let current = this.state.crumbs.splice(0, pos+1)
    this.setState({ crumbs: current })
  }

  render() {
    let breadCrumbs = this.state.crumbs.map((item, i) => 
      <Breadcrumb.Item key={i}>
        <Link to={item.link} style={{fontSize: 20}} onClick={() => {this.removeBreadcrumb(i)}}>{item.title}</Link>
      </Breadcrumb.Item>
    )
    if (!this.state.fetching) {
      return (
        <div>
         
          <div style={{paddingTop: 40}}>
            <Breadcrumb>
              {breadCrumbs}
            </Breadcrumb>
            <div>
              <Switch>
                <Route path={`/courses/:cid/module/:mid/exercise/:eid`} component={Exercise} />
                <Route path={`/courses/:cid/module/:mid`}>
                  <p style={{fontSize: 12}}>Description: {this.state.selectedModule.description}</p>
                  <ExerciseTable
                    exercises={this.state.selectedModule.exercises}
                    addBreadcrumb={this.addBreadcrumb}
                  />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
        </div>
      )
    }
  }
}

export default StudentDashboard