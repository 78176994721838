import React from 'react'
import { Button } from 'antd'
import HanziWriter from 'hanzi-writer'

class HanziLearning extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      delayBetweenStrokes: 800,
      delayBetweenLoops: 2000,
      attempt: false,
      character: this.props.selectedQuestion.question_json.hanzi
    }
    this.animationRef = React.createRef()
    this.attemptRef = React.createRef()
  }

  
  makeAnimationHanzi = () => {
    const writer = HanziWriter.create('hanzi-animation', this.state.character, {
      width: window.innerHeight*0.4,
      height: window.innerHeight*0.4,
      padding: 5,
      delayBetweenStrokes: this.state.delayBetweenStrokes,
      delayBetweenLoops: this.state.delayBetweenLoops
    })
    this.animationRef = writer
    writer.loopCharacterAnimation()
  }

  makeAttemptHanzi = () => {
    const writer = HanziWriter.create('hanzi-attempt', this.state.character, {
      width: window.innerHeight*0.4,
      height: window.innerHeight*0.4,
      padding: 5,
      showCharacter: false
    })
    this.attemptRef = writer
    writer.quiz()
  }

  resetAttempt = () => {
    console.log(this.attemptRef)
    this.attemptRef.setCharacter(this.state.character)
    this.attemptRef.quiz()
  }

  changeToAttempt = async () => {
    let hanziContainer = document.querySelector('#hanzi-animation')
    while (hanziContainer.firstChild) hanziContainer.removeChild(hanziContainer.firstChild)
    this.setState({ attempt: true }, () => this.makeAttemptHanzi())
  }

  changeToAnimation = async () => {
    let hanziContainer = document.querySelector('#hanzi-attempt')
    while (hanziContainer.firstChild) hanziContainer.removeChild(hanziContainer.firstChild)
    this.setState({ attempt: false }, () => this.makeAnimationHanzi())
  }

  changeAnimationSpeed = (newSpeed) => {
    if (newSpeed < 100) {
      return
    }
    if (newSpeed >= 2000) {
      return
    }
    let hanziContainer = document.querySelector("#hanzi-animation")
    while(hanziContainer.firstChild) hanziContainer.removeChild(hanziContainer.firstChild)
    this.setState({ delayBetweenStrokes: newSpeed })
    this.makeAnimationHanzi()
    // then make a new one at a faster speed.
  }

  componentDidMount = () => {
    this.makeAnimationHanzi()
  }

  render = () => {
    const selectedQuestion = this.props.selectedQuestion

    // if it's learning, then watch the animation with speed up / down.
    if (this.state.attempt) {
      return (
        <div style={{flex: 1, display: "flex", flexDirection: "column" , justifyContent: "center", textAlign: "center"}}>
          <p>Try drawing it yourself.</p>
          <div id="hanzi-attempt">
          </div>
          <div id="button-container" style={{marginBottom: 50}}>
            <Button onClick={() => {this.resetAttempt()}}>Reset</Button>
          </div>
            <Button type="primary" onClick={() => this.changeToAnimation()}>
              Watch Animation
            </Button>
        </div>
      )
    }
    return (
      <div style={{flex: 1, display: "flex", flexDirection: "column" , justifyContent: "center", textAlign: "center"}}>
        <p> Watch closely to see the order and direction of each stroke. </p>
        <div id="hanzi-animation">
        </div>
        <div id="button-container" style={{marginBottom: 50}}>
          <Button onClick={() => {this.changeAnimationSpeed(this.state.delayBetweenStrokes+100)}}>
            Slower
          </Button>
          <Button onClick={() => {this.changeAnimationSpeed(this.state.delayBetweenStrokes-100)}}>
            Faster
          </Button>   
        </div>
        <Button type="primary" onClick={() => this.changeToAttempt()}>
          Try it yourself
        </Button>
      </div>
    )
  }
}

export default HanziLearning