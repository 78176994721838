import React, { Component } from "react";
import { Table, Input, Icon, Button, Divider, notification, Popconfirm, Tag, Radio } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import ReactAudioPlayer from "react-audio-player";
import AssetsForm from "./AssetsForm"
import SingleAssetView from "./SingleAssetView";
import AssetMultiple from './AssetMultiple'
import { Route } from "react-router-dom";

class AssetsView extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: true, 
      dataSource: [], 
      visibleAssetForm: false,
      currentAsset: undefined,
      visibleSingleAsset: false,
      filter: "no_filter",
      visibleMultipleModal: false,
    }
  }

  componentDidMount = async () => {
    // const response = await axios.get("assets/get_assets/")
    const response = await axios.get(`assets/get_related_assets/?userId=${sessionStorage.getItem("userId")}`)
    this.setState({ dataSource: response.data, fetching: false })
  };

  updateAsset = (dataSource) => {
    this.openNotificationWithIcon("success", "Asset has been modified")
    this.setState({ dataSource, visibleAssetForm: false, currentAsset: undefined })
  }

  searchEntry(dataSource, searchText) {
    let searchFiltered
    if (!searchText) searchFiltered = dataSource;
    else {
      searchFiltered = dataSource.filter(
        it =>
          !it.title || (it.title && it.title.toLowerCase().includes(searchText))
          || (it.description && it.description.toLowerCase().includes(searchText))
      );
    }
    if (this.state.filter === "no_filter") return searchFiltered
    else {
      return searchFiltered.filter(it => !it.type || it.type === this.state.filter)
    }
  }

  filterRadio = (e) => {
    this.setState({ filter: e.target.value })
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  updateDataSource = (dataSource) => {
    this.setState({dataSource, visibleMultipleModal: false})
    this.openNotificationWithIcon("success", "Asssts Added!")
  }

  handleOnAddAsset = async (course, props) => {
    const { currentAsset } = this.state;
    const newAsset = { ...currentAsset, ...course };
    this.setState({ fetching: true });
    const formData = new FormData()
    formData.append('files[]', newAsset.upload.file)
    formData.append('asset', JSON.stringify(newAsset))
    // need to first convert files into formdata?

    try {
      const { data } = currentAsset
        ? await axios.put("assets/assets/", formData)
        : await axios.post("assets/assets/", formData);
      const index = this.state.dataSource.findIndex(
        record => record.id === data.id
      );
      props.resetFields();
      if (currentAsset) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, data]
          : [
              ...this.state.dataSource.slice(0, index),
              data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        visibleAssetForm: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  handleOnDeleteAsset = async (id) => {
    this.setState({ fetching: true });
    try {
      const deleteRequest = await axios.delete("assets/assets", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        record => record.id !== id
      );
      if (deleteRequest.status === 204) {
        this.openNotificationWithIcon("success", "Record deleted successfully");
      }
      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  }

  addChinese = async () => {
    const { data: dataSource } = await axios.get('assets/add_chinese/')
    console.log(dataSource)
  }

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;
    const columns = [
      {
        title: "Asset Title",
        dataIndex: "title",
        editable: false,
        key: "title",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: text => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: text => {
          if (text === "audio") {
            return (
              <Tag color="blue">Audio</Tag>
            )
          }
          return (
            <Tag color="green">Picture</Tag>
          );
        }
      },
      {
        title: "Preview",
        key: "preview",
        dataIndex: "preview",
        render: (text, record) => {
          if (record.type === "audio") {
            let url = process.env.REACT_APP_ASSET_URL+"/"+record.s3_link
            return (
              <ReactAudioPlayer
                src={url}
                controls
              />
            )
          } else if (record.type === "picture") {
            let url = process.env.REACT_APP_ASSET_URL+"/"+record.s3_link
            return (
              <img src={url} height="60" />
            )
          }
        }
      },
      {
        title: "Author",
        key: "author",
        dataIndex: "author",
        render: (text, record) => {
          return (
            <p>{text}</p>
          )
        }
      },
      {
        title: "License Type",
        key: "license_type",
        dataIndex: "license_type",
        render: (text, record) => {
          if (text === "ALL_RIGHTS_RESERVED") {
            return (<Tag color="green">All Rights Reserved</Tag>)
          } else if (text === "UNDER_THE_ACT") {
            return (<Tag color="blue">Under the Act</Tag>)
          }
          return (
            <Tag color="blue">Creative Commons</Tag>
          )
        }
      },
      {
        title: "Accessibility",
        key: "accessibility",
        dataIndex: "accessibility",
        render: (text) => {
          return (
            <p>{text}</p>
          )
        }
      },
      {
        title: "Last modified",
        key: "date_created",
        dataIndex: "date_created",
        render: (text, record) => {
          return (
            <p>{this.parseDate(text)}</p>
          )
        }
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => {
          return (
            <div>
              <Button type="link"
                onClick={() => {this.setState({ visibleSingleAsset: true, currentAsset: record})}}
              >
                View
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={() => {console.log(record); this.setState({visibleAssetForm: true, currentAsset: record})}}>
                Edit
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to delete the asset? This will remove it from all questions."
                onConfirm={() => {this.handleOnDeleteAsset(record.id)}}
              >
                <Button type="link">
                  Delete
                </Button>
              </Popconfirm>

            </div>
          )
        }
      }
    ]

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px", fontSize: 40 }}>
            Assets Library
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Button
              onClick={() => {this.addChinese()}}
            >
              Add Chinese
            </Button>
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title or Description"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
           
            <Button style={{float: 'right'}}
              type="primary"
              onClick={() => {
                this.setState({ visibleMultipleModal: true })
              }}
            >
              Create Asset(s)
            </Button>
          </div>

          <div style={{width: "70%", display: "flex", flex: 1, paddingBottom: 20, flexDirection: "column"}}>
              <p style={{paddingRight: 30}}>Filter by Type</p>
              <Radio.Group onChange={this.filterRadio} defaultValue="no_filter">
                <Radio.Button value="no_filter">No filter</Radio.Button>
                <Radio.Button value="audio">Audio</Radio.Button>
                <Radio.Button value="picture">Picture</Radio.Button>
              </Radio.Group>
            </div>

          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            pagination={{ position: 'both', defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
          />

        </div>
      </>
    )
  }

  handleOnCancel() {
    this.setState({ visibleCreateQuestion: false })
  }

  goBack = () => {
    this.setState({ visibleMultipleModal: false})
  }

  parseDate = (date) => {
    const parse = new Date(date)
    const day = parse.getDate()
    const month = parse.getMonth()
    const year = parse.getFullYear()
    return `${day}/${month}/${year}`
  }

  render() {
    if (this.state.visibleMultipleModal) {
      return (
        <AssetMultiple 
          visible={this.state.visibleMultipleModal}
          goBack={() => this.goBack()}
          updateDataSource={this.updateDataSource}
        />
      )
    } else {
      return (
        <div>
          {this.TableView()}
          <SingleAssetView
            onCancel={()=>{this.setState({ visibleSingleAsset: false})}}
            visible={this.state.visibleSingleAsset}
            selectedAsset={this.state.currentAsset}
          />
          <AssetsForm
            updateDataSource={this.updateAsset}
            visible={this.state.visibleAssetForm}
            onCancel={() => {this.setState({ visibleAssetForm: false })}}
            selectedAsset={this.state.currentAsset}
          />
        </div>
      )
    }
  }
}

export default AssetsView