import React, { Component } from "react";

import { Modal, Button } from "antd";

import ReactAudioPlayer from "react-audio-player"

class SingleAssetView extends Component {

  render() {
    let title, description, type, file
    if (this.props.selectedAsset !== undefined) {
      title = this.props.selectedAsset.title
      description = this.props.selectedAsset.description
      type = this.props.selectedAsset.type
      let url = process.env.REACT_APP_ASSET_URL+"/"+this.props.selectedAsset.s3_link

      if (type === "picture") {
        // download from s3.
        file = (
          <img src={url} width="300" />
        )
      } else if (type === "audio") {
        file = (
          <ReactAudioPlayer
            src={url}
            controls
          />
        )
      }
    }
    return (
      <>
        <Modal
          visible={this.props.visible}
          onOk={() => this.props.onCancel()}
          onCancel={() => this.props.onCancel()}
          style={{minWidth: "70%"}}
          footer={[
            <Button onClick={() => this.props.onCancel()}>
              OK
            </Button>
          ]}
        >
          <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding: 30}}>
            <p>Asset: {title}</p>
            <p>Description: {description}</p>
            <p>Type: {type}</p>
            {file}
          </div>
        </Modal>
      </>
    );
  }
}

export default SingleAssetView;
